#page-como-funciona{

  > section{
    position: relative;
    z-index: 2;
  }

  > section:not(.section-footer) header{
    font-size: 72px;
    font-weight: 900;
    color: color(spring-green);
    margin-bottom: 35px;
  }

  .section-hero-como-funciona{

    @media (max-width: 595px){
      height: 150px;
    }
    img{
      width: 100%;

      @media (max-width: 595px){
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section-como-funciona{
    padding: 100px 0 50px;
    z-index: 5;

    .flex-wrapper{
      margin-bottom: 100px;

      @media (max-width: 595px){
        margin-bottom: 50px;
      }

      .description{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 595px){
          flex-direction: column;
        }

        p{
          font-size: 18px;
          line-height: 25px;
          color: #808080;

          @media (max-width: 595px){
            font-size: 16px;
            line-height: 23px;
          }
        }

        .column{

          @media(max-width: 595px){
            width: 100%;
          }

          > *:not(:last-child) {
            margin-bottom: 15px;
          }
        }

      }
    }
    .infos-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      @media (max-width: 595px){
        flex-direction: column;
        margin-bottom: 0;
      }

      .item{
        width: calc(100% / 4 - 15px);
        border: 1px solid color(spring-green);
        background-color: transparent;
        padding: 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:not(:last-child){
          margin-right: 15px;

          @media (max-width: 595px){
            margin-right: 0;
          }
        }

        @media (max-width: 595px){
          width: 100%;
          margin-bottom: 15px;
        }

        h2{
          font-size: 64px;
          font-weight: 900;
          color: color(picton-blue);
          margin-bottom: 15px;
        }
        p{
          font-size: 24px;
          color: color(valhalla);
          text-align: center;
          line-height: 1.4;
        }

      }
    }
  }
  .section-empresas{
    margin-bottom: 100px;

    @media (max-width: 595px){
      margin-bottom: 50px;
    }

    .background{
      position: absolute;
      width: 100%;
      height: 100%;
      top: -300px;
      left: 0;
      z-index: 1;

      @media (max-width: 595px){
        top: 0;
      }

      img{
        width: 100%;
      }
    }

    .container{
      position: relative;
      z-index: 2;
    }

    header.title{
      font-size: 42px;
      margin-bottom: 75px;
    }
    .section-empresas-content{

      .flex-wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &.full-width{

          .left, .right{
            width: 100%;
          }
        }

        @media (max-width: 595px){
          flex-direction: column;
        }

        .left, .right{
          width: calc(50% - 30px);

          @media (max-width: 595px){
            width: 100%;
          }
        }

        .left{

          .description{
            font-size: 18px;
            line-height: 25px;
            color: #808080;

            @media (max-width: 595px){
              font-size: 16px;
            }
          }
          @media (max-width: 595px){
            margin-bottom: 25px;
          }
        }
        .right{

          ul{

            li{
              color: color(valhalla);
              font-weight: 700;
              font-size: 22px;
              margin-bottom: 20px;
              position: relative;

              @media (max-width: 595px){
                font-size: 18px;
              }

              &::before{
                content: '•';
                margin-right: 5px;
                color: color(picton-blue);
              }
            }
          }
        }
      }
    }
  }
  .section-sistema{
    margin-bottom: 100px;

    @media (max-width: 595px){
      margin-bottom: 50px;
    }

    header.title{
      font-size: 48px;
      text-align: center;
      margin-bottom: 75px;
    }

    .section-sistema-content{

      .flex-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 595px){
          flex-direction: column;
        }

        .left, .right{
          width: calc(50% - 30px);

          @media (max-width: 595px){
            width: 100%;
          }
        }

        .left{

          .featured-img{
            border-radius: 8px;
            box-shadow: 0px 40px 60px 0px rgba(133, 167, 179, 0.45);
            overflow: hidden;

            img{
              width: 100%;
              height: 100%;
              object-fit:cover;
              margin-bottom: -5px;

            }

          }

          @media (max-width: 595px){
            margin-bottom: 25px;
          }
        }
        .right{

          .description{

            p{
              font-size: 18px;
              line-height: 25px;
              color: #808080;

              @media (max-width: 595px){
                font-size: 16px;
              }
            }
            p:not(:last-child){
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
  .section-servicos{
    margin-bottom: 100px;

    @media (max-width: 595px){
      margin-bottom: 50px;
    }

    header.title{
      font-size: 48px;
    }
    .section-servicos-content{

      .flex-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 595px){
          flex-direction: column;
        }

        .left, .right{
          width: calc(50% - 30px);

          @media (max-width: 595px){
            width: 100%;
          }
        }

        .left{

          .description{

            p{
              font-size: 18px;
              line-height: 25px;
              color: #808080;
            }
            p:not(:last-child){
              margin-bottom: 25px;
            }
          }
          @media (max-width: 595px){
            margin-bottom: 25px;
          }
        }
        .right{

          margin-top: -82px;

          @media (max-width: 595px){
            margin-top: 0;
          }

          ul{

            li{
              display: inline-block;
              padding: 30px;
              border-radius: 8px;
              background-color: color(picton-blue);
              color: #fff;
              width: 100%;
              font-weight: 700;
              font-size: 22px;
              position: relative;
              box-shadow: 0px 40px 60px 0px rgba(47, 197, 244, 0.45);

              @media (max-width: 595px){
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
              }

              &::before{
                content: '•';
                margin-right: 5px;
                color: #fff;
              }
            }
            li:not(:last-child){
              margin-bottom: 20px;
            }
          }
        }

        &.full-width{
          flex-direction: column;

          .left, .right{
            width: 100%;
          }
        }
      }
    }
  }
  .section-perguntas{
    padding-bottom: 100px;

    @media (max-width: 595px){
      display: none;
    }

    .background{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
      overflow: hidden;
      bottom: 0;

      @media (max-width: 595px){
        top: 0;
      }

      img{
        position: relative;
        top: -100px;
        width: 100%;
        // height: 630px;
        object-fit: cover;
        object-position: top;
      }
    }

    .container{
      position: relative;
      z-index: 2;
    }

    header.title{
      font-size: 48px;
    }

    .section-perguntas-content{

      ul.navigation-perguntas{

        flex-direction: column;

        li{
          width: 100%;

          button{
            width: 100%;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title{
              font-size: 22px;
              color: color(valhalla);
              font-weight: 700;

              span{
                margin-right: 15px;
                color: color(picton-blue);
              }
            }
            .arrow{
              transition: transform .4s;
              padding-right: 15px;
            }
            .arrow.rotate{
              transform: rotate(-180deg);
            }
          }
        }
        li:not(:last-child){
          border-bottom: 1px solid color(picton-blue);
        }
        .content{
          width: 100%;
          height: 0;
          opacity: 0;
          visibility: hidden;
          transition: height .4s, opacity .4s;
          // transition: opacity .4s;
          // transition: padding .4s;

          ul{

            li{
              font-size: 18px;
              color:#808080;
              border-bottom: 0;
              margin-top: 15px;

              a{
                color:color(valhalla);
                font-weight: 700;
                text-decoration: underline!important;

                &:hover{
                  color: color(spring-green);
                }
              }

              &::before{
                content: '•';
                margin-right: 5px;
                color: #808080;
              }
            }
          }
          &.active{
            height: 100%;
            padding: 30px;
            opacity: 1;
            visibility: visible;
          }

          p{
            color: grey;
            font-size: 18px;
            margin: 15px 0;
            line-height: 2;
          }
          b,strong{
            font-weight: 700;
            color: color(valhalla);
            font-weight: 18px;
          }
        }
      }
    }
  }
}
