/** @list: Themes. */
// $themes: theme_1, theme_2, theme_3;

/** @list: Colors. */
$color: (
  spring-green:   #0eea8c,
  valhalla:       #1f194f,
  picton-blue:    #2fc5f4,
  breeze:         #e0eff4,
  coal:           #4c4c4c,
  pale-grey:      #cccccc
);

/**
 * Returns a color from its name.
 *
 * @param  {string} $keys... Color names. Include multiple values for nested variations.
 * @return {string}          Color code.
 */
@function color($keys...) {
  $map: $color;
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

/*! Font families list */
$fonts: (
  main: Brandon Grotesque,
  headlines: Verdana
);

// |> Returns a font family name from its category
@function font($font-category) {
  @return map-get($fonts, $font-category);
}

/** @list: Transitions. */
$transitions: (
  sol: .3s,
  fast: .5s,
  default: .7s,
  slow: 1s
);

/**
 * Returns a transition duration from a pre-defined speed.
 *
 * @param  {string} $speed Transition speed.
 * @return {Number}        Transition duration.
 */
@function time($speed) {
  @return map-get($transitions, $speed);
}

/** @list: Timing-functions. */
$timing_functions: (
  inout: cubic-bezier(.5, 0, 0, 1),
  fade: cubic-bezier(0, 0, .3, 1)
);

/**
 * Returns a transition-timing-function setting from its type.
 *
 * @param  {string}   $type Timing-function type.
 * @return {function}       Timing-function setting.
 */
@function ease($type) {
  @return map-get($timing_functions, $type);
}
