
@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../assets/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
        url('../assets/fonts/BrandonGrotesque-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../assets/fonts/BrandonGrotesque-Black.woff2') format('woff2'),
        url('../assets/fonts/BrandonGrotesque-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('../assets/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
        url('../assets/fonts/BrandonGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
