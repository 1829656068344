#page-contato{

  .section-hero-contato{

    @media (max-width: 595px){
      height: 150px;
    }
    img{
      width: 100%;

      @media (max-width: 595px){
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section-contato{
    padding: 100px 0;

    @media (max-width: 595px){
      padding: 50px 0;
    }

    header.title{
      font-size: 72px;
      font-weight: 900;
      color: color(spring-green);
      margin-bottom: 25px;
    }
    h6{
      font-size: 16px;
      color: color(valhalla);
      font-weight: normal;

      strong{
        color: color(picton-blue);
      }
    }

    .section-contato-content{

      .flex-wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 595px){
          flex-direction: column;
        }

        .left{
          width: calc(30% - 15px);

          @media (max-width: 595px){
            width: 100%;
          }
        }
        .right{
          width: calc(70% - 15px);

          @media (max-width: 595px){
            width: 100%;
          }

          .form{

            .wrapper{
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              @media (max-width: 595px){
                flex-direction: column;
              }

              & > div{
                width: calc(50% - 15px);

                @media (max-width: 595px){
                  width: 100%;
                }

              }
              .form-right{

                .field-group{

                  &:last-child{
                    height: 208px;
                  }
                }
              }
            }
            .form-submit{
              display: flex;
              align-items: center;
              justify-content: flex-end;

              button{

                text-transform: uppercase;

                @media (max-width: 595px){
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
