/** Hide svg. */
.svg-hide {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.form {
  .placeholder {
    pointer-events: none;
  }
}

.box-status {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.75);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: visibility .4s, opacity .4s;

  .box {
    padding: 50px 100px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 665px;
    max-width: 100%;
    box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5);

    header{
      font-size: 36px;
      font-weight: 900;
      color: #ff284b;
      margin-bottom: 20px;
    }

    p {
      font-size: 22px;
      color: #4c4c4c;
      line-height: 26px;
    }

    .btn-fechar-box{
      margin: 25px auto 0;
    }
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
