.screen-progress {
  background-color: #fff;
  border-radius: 5px;
  color: color(spring-green);
  text-align: center;
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top time(default) ease(inout), opacity time(default) ease(fade);
  box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5);

  &.open {
    margin-top: 0;
    opacity: 1;
  }

  .screen-message {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 15px;
  }

  .spinner {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: relative;

    @keyframes progress_spinner {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      50% {
        opacity: .3;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }

    span {
      background-color: color(spring-green);
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite;

      &:nth-child(2) {
        animation-delay: .5s;
      }
    }
  }
}
