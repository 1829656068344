#page-contratacao{
  .progress {
    background-color: fade-out(black, .3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &-wrapper {
      color: #1f194f;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: white;
      padding: 32px 60px 29px;
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      transition: background-color .3s, padding .3s;

      span {
        line-height: 1.4;
        transition: color .3s;
      }

      button {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: color(valhalla);
        background-color: color(spring-green);
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        padding: 8px 20px;
        border-radius: 4px;
        box-shadow: 0 2px 4px fade-out(black, .8);
        transition: background-color .3s, color .3s, opacity .3s, visibility .3s;

        &:hover {
          color: white;
          background-color: color(valhalla);
        }
      }
    }

    &.success .progress-wrapper {
      background-color: #0eea8c;
    }

    &.error {
      .progress-wrapper {
        padding-bottom: 36px;
      }

      span {
        color: #ff284b;
      }
    }

    &:not(.error) button {
      opacity: 0;
      visibility: hidden;
    }
  }

  .section-hero-contratacao {
    @media (max-width: 595px) {
      height: 150px;
    }

    img {
      width: 100%;

      @media (max-width: 595px) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .section-contratacao{
    padding: 100px 0;

    @media (max-width: 595px){
      padding: 50px 0;
    }

    .section-contratacao-content{

      header.title{
        font-size: 72px;
        font-weight:900;
        color: color(spring-green);
        margin-bottom: 75px;

        @media (max-width: 595px){
            margin-bottom: 35px;
        }
      }
      .description{

        p{
          font-size: 18px;
          line-height: 25px;
          color: #808080;
          margin-bottom: 25px;

          @media (max-width: 595px){
            font-size: 16px;
            line-height: 23px;
          }
        }
      }

      .flex-wrapper{

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 768px){
          flex-direction: column-reverse;
        }


        .left{
          width: calc(65% - 15px);

          @media (max-width: 768px){
            width: 100%;

          }

          .item{

            .form{

              .subtitle{
                font-size: 18px;
                color: color(valhalla);
                font-weight: 900;
                margin-bottom: 30px;

                span{
                  color: color(picton-blue);
                  margin-right: 5px;
                }
              }
              .wrapper{

                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 50px;
                padding-bottom: 50px;
                border-bottom: 2px solid color(spring-green);

                @media (max-width: 768px){
                  flex-direction: column;
                }

                & > div{
                  width: calc(50% - 15px);

                  @media (max-width: 768px){
                    width: 100%;
                  }
                }

                .fields-endereco{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .field-group{
                    width: calc(50% - 15px);
                  }
                }

                .fields-cartao{
                  margin-top: -33px;
                  margin-bottom: 35px;

                  @media (max-width: 768px){
                    margin-top: 0;
                  }
                }
              }
              .cartoes{

                margin-bottom: 50px;

                span{
                  font-size: 18px;
                  color: color(valhalla);
                  margin-bottom: 15px;
                  display: block;
                }

                img{

                  margin-bottom: 10px;

                  &:not(:last-child){
                    margin-right: 10px;
                  }
                }
              }
              .form-btn-wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .form-submit{

                  @media (max-width: 768px){
                    width: 100%;
                  }

                  button{

                    text-transform: uppercase;

                    @media (max-width: 595px){
                      width: 100%;
                    }
                  }
                }
              }
            }

          }
        }
        .right{
          width: calc(35% - 15px);

          @media (max-width: 768px){
            width: 100%;
          }

          .plano-box{
            background-color: color(valhalla);
            border-radius: 8px;
            padding: 30px;
            text-align: center;
            box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5);
            @media (max-width: 768px){
              width: 100%;
            }

            @media (max-width: 768px){
              margin-bottom: 35px;
            }

            header{
              font-size: 48px;
              font-weight: 900;
              color: #fff;
              text-align: center;
              margin-bottom: 25px;
            }
            .box-subtitle{
              width: 80%;
              font-size: 18px;
              font-weight: 900;
              color: color(spring-green);
              text-transform: uppercase;
              text-align: center;
              padding-bottom: 15px;
              border-bottom: 1px solid color(spring-green);
              display: inline-block;
            }
            .faturamento-content, .regime-content{
              width: 80%;
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 15px auto;
              padding: 15px 0;
              border-bottom: 1px solid color(spring-green);
              display: inline-block;

              h4{
                color: color(picton-blue);
                margin-bottom: 5px;
              }
              p{
                color: #fff;
              }
            }
            .full-price{

              margin-top: 25px;

              h6{
                text-align: center;
                color: #0eea8c;
                font-size: 14px;
                margin-bottom: 10px;
              }
              .price{
                text-align: center;

                span{
                  color: #fff;
                  font-weight: 900;
                }

                .moeda{
                  font-size: 32px;
                }
                .valor{
                  font-size: 63px;
                }
                .centavos{
                  font-size: 32px;
                  position: absolute;
                }
                .perido{
                  font-size: 14px;
                  color: color(spring-green);
                }
              }
            }
          }
        }
      }
    }
  }
}
