#main-header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 50;
	transition: background-color time(default) ease(inout);

	.flex-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 45px 0;
		transition: padding time(default) ease(inout);;

		.logo {
			position: relative;
			margin: 0;
			padding: 0;
			pointer-events: initial;

			a {
				display: inline-block;
			}
		}

		.menu-toggler {
			position: relative;
			display: flex;
			align-items: center;
			height: 32px;
			margin: 0;
			padding: 0;
			pointer-events: initial;
			transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

			&--border-top {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 2px;

				&::before,
				&::after {
					content: '';
					background-color: white;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					height: 2px;
					z-index: 1;
					transition: transform time(slow) ease(inout);
				}

				&::before {
					transform-origin: 0% 50%;
					transform: scaleX(0.25);
				}

				&::after {
					transform-origin: 100% 50%;
					transform: scaleX(0.6);
				}
			}

			&--border-bottom {
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				height: 2px;

				&::before,
				&::after {
					content: '';
					background-color: white;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					height: 2px;
					transition: transform time(slow) ease(inout);
					z-index: 1;
				}

				&::before {
					transform-origin: 0% 50%;
					transform: scaleX(0.6);
				}

				&::after {
					transform-origin: 100% 50%;
					transform: scaleX(0.25);
				}
			}

			span {
				&:nth-child(2) {
					color: white;
					font-size: 12px;
					font-weight: bold;
					text-transform: uppercase;
					display: inline-block;
					letter-spacing: 1.5px;
					margin: 0;
					padding: 0;
				}
			}

			&:hover {
				.menu-toggler--border-top {
					&::after {
						transform: scaleX(1);
					}

					&::before {
						transform: scaleX(0);
					}
				}

				.menu-toggler--border-bottom {
					&::after {
						transform: scaleX(0);
					}

					&::before {
						transform: scaleX(1);
					}
				}
			}
		}

		.cta {
			position: relative;
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;
			pointer-events: initial;

			button {
				position: relative;
				margin: 0;
				padding: 0;
				cursor: pointer;

				svg path {
					fill: white;
				}

				&:not(:last-child) {
					margin-right: 40px;
				}
			}
		}
	}

	/** Menu open. */
	&.menu-open .flex-wrapper .menu-toggler {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	/** Sticky and single. */
	&.sticky{

		.navigation{
			padding: 20px 0;
			transition: padding .4s;

		}
	}
	&.sticky,
	&.single {
		pointer-events: initial;
		background-color: #060A32;

		.flex-wrapper {
			padding: 15px 0;
		}
	}
}
