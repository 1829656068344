#page-sobre{

  > section:not(.section-footer) header{
    font-size: 72px;
    font-weight: 900;
    color: color(spring-green);
    margin-bottom: 35px;
  }

  .section-hero-sobre{

    @media (max-width: 595px){
      height: 150px;
    }
  img{
      width: 100%;

      @media (max-width: 595px){
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section-sobre{
    padding: 100px 0 50px;

    .flex-wrapper{
      margin-bottom: 100px;
      width: 70%;

      @media (max-width: 595px){
        margin-bottom: 50px;
        width: 100%;
      }

      .description{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 595px){
          flex-direction: column;
        }

        p{
          font-size: 18px;
          line-height: 25px;
          color: #808080;

          @media (max-width: 595px){
            font-size: 16px;
            line-height: 23px;
          }
        }

        .column{
          width: calc(50% - 15px);

          @media(max-width: 595px){
            width: 100%;
          }
        }

      }
    }
    .infos-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      @media (max-width: 595px){
        flex-direction: column;
        margin-bottom: 0;
      }

      .item{
        width: calc(100% / 3 - 15px);
        background-color: color(picton-blue);
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0px 40px 60px 0px rgba(47, 197, 244, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:not(:last-child){
          margin-right: 15px;

          @media (max-width: 595px){
            margin-right: 0;
          }
        }

        @media (max-width: 595px){
          width: 100%;
          margin-bottom: 15px;
        }

        h2{
          font-size: 64px;
          font-weight: 900;
          color: #fff;
          margin-bottom: 15px;
        }
        p{
          font-size: 24px;
          color: color(valhalla);
          text-align: center;
        }

      }
    }
  }
  .section-depoimentos{
    position: relative;
    margin-bottom: 150px;
    z-index: 3;

    header.title{
      text-align: center;
      margin-bottom: 75px;
    }
  }
  .section-depoimentos-content{

    .depoimentos{
      width: 690px;
      max-width: 100%;
      margin: 75px auto 100px;

      .item{

        p{
          font-size: 24px;
          text-align: center;
          line-height: 26px;
          color: color(valhalla);
          padding: 0 115px;
          padding-bottom: 10px;

          @media (max-width: 595px){
            font-size: 18px;
            line-height: 25px;
            padding: 0;
          }
        }
      }
    }
    .owl-nav{
      position: absolute;
      top: 50%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translateY(-50%);

      @media(max-width: 595px){
        display: none;
      }
    }
    .owl-dots{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      position: absolute;
      bottom: -60px;
      width: 100%;

      .owl-dot{
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: #a1a9bd;
        border-radius: 50%;
        transition: background-color .4s;

        &:not(last-child){
          margin-left: 10px;
        }

        &.active{
          background-color: color(valhalla);
        }

        &:hover{
          background-color: color(spring-green);
        }
      }
    }
  }
  .section-newsletter{
    position: relative;
    padding-bottom: 100px;
    z-index: 2;

    .background{
      width: 100%;
      position: absolute;
      bottom: 0;

      img{
        width: 100%;
      }
    }

    .newsletter{
      position: relative;
      z-index: 3;

      .flex-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 595px){
          flex-direction: column;
        }

        .left{
          background-color: color(spring-green);
          box-shadow: 0px 40px 60px 0px rgba(14, 232, 140, 0.4);
          border-radius: 8px 0 0 8px;

          @media (max-width: 595px){
            border-radius: 8px 8px 0 0;
            box-shadow: none;
          }

          h3{
            font-size: 32px;
            color: #fff;

            @media (max-width: 595px){
              font-size: 24px;
            }
          }
        }
        .right{
          background-color: #fff;
          box-shadow: 0px 40px 60px 0px rgba(192, 214, 222, 0.45);
          border-radius: 0 8px 8px 0;

          @media (max-width: 595px){
            border-radius: 0 0 8px 8px;
          }

          .form{
            width: 100%;
            position: relative;

            .field-group{
              position: relative;
              width: 100%;
              margin: 0;
              padding: 0;
              border-bottom: 1px solid color(spring-green);
              transition: border-color .5s cubic-bezier(0,0,.3,1);

              .field{
                color: #808080;
                font-size: 14px;
                font-weight: normal;
                position: relative;
                background-color: transparent;
                border:none;
                width: 100%;
                padding: 0 0 5px;
                z-index: 1;
              }
              .placeholder{
                color: #808080;
                font-size: 14px;
                font-weight: normal;
                position: absolute;
                top: 2px;
                left: 0;
                z-index: 2;
                transition: opacity time(fast) ease(fade);
              }
              &.invalid{
                border-color: red;
              }
              &.not-empty, &.focus{
                border-color: color(picton-blue);

                .placeholder{
                  opacity: 0;
                }
              }
            }
          }
          .form-submit{
            position: absolute;
            top: -20px;
            right: 0;

            button{
            transition: transform .4s;
              &:hover{
                img{
                  transform: scale(1.2);
                }
            }

            }
          }
        }

        .left, .right{
          width: 50%;
          height: 205px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 50px;

          @media(max-width: 595px){
            width: 100%;
            height: 150px;
            padding: 30px;
          }

          @media (min-width: 596px) and (max-width: 768px){
            width: 100%;
          }

        }
      }
    }
  }

}
