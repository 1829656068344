/** @import: Vendor. */
@import '../vendor/normalize/normalize.css';
@import '../vendor/fancybox/jquery.fancybox.min.css';
@import '../vendor/owl/owl.carousel.min.css';

/** @import: Main. */
@import 'reset';
@import 'vars';
@import 'fonts';

/** Body. */
body {
	font-family: font(main);
  font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body.no-overflow{
	overflow: hidden;
}

[data-split-by="word"] {
	.w {
		display: inline-block;
		opacity: 0;
		transform: translateY(10px);
		transition: opacity .4s, transform .4s cubic-bezier(.4, 0, 0, 1);

		@for $x from 1 to 15 {
			&:nth-child(#{$x}) {
				transition-delay: #{($x - 1) * .15s};
			}
		}
	}

	&.entered .w {
		opacity: 1;
		transform: translateY(0);
	}
}

::selection{
	background-color: color(picton-blue);
	color:#fff;
}
a{
	transition: 200ms ease-in-out;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: color(spring-green);
}
[type="radio"]:not(:checked) + label:before {
	background-color: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #fff;
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.form{

	.group-fields{
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		flex-direction: column;

		label{
			width: 100%;
			margin-bottom: 15px;
			font-size: 18px;
			color: color(valhalla);

			strong{
				color: color(picton-blue);
				margin-right: 3px;
			}
		}

		.fields{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.field-group{
				margin-bottom: 0;

			}

		}
	}

	.field-group{
		position: relative;
		width: 100%;
		margin: 0;
		padding: 0;
		border-bottom: 1px solid color(picton-blue);
		transition: border-color .5s cubic-bezier(0,0,.3,1);
		margin-bottom: 35px;

		.bandeiraCartao{
			position: absolute;
			right: 0;
		}

		.field{
			color: #808080;
			font-size: 14px;
			font-weight: normal;
			position: relative;
			background-color: transparent;
			border:none;
			width: 100%;
			padding: 0 0 5px;
			z-index: 1;
		}
		.placeholder{
			color: #808080;
			font-size: 14px;
			font-weight: normal;
			position: absolute;
			top: 2px;
			left: 0;
			z-index: 2;
			transition: opacity time(fast) ease(fade);

			strong{
				color: color(picton-blue);
				margin-left: 3px;
			}
		}
		&.invalid{
			border-color: red;
		}
		&.not-empty, &.focus{
			border-color: color(picton-blue);

			.placeholder{
				opacity: 0;
			}
		}
	}
}
/** Layout. */
.container {
  width: 1200px;
  max-width: calc(100vw - 40px);
  margin: 0 auto;
}
.container-medium{
	width: 970px;
  max-width: 100%;
	margin: 0 auto;
}

.btn{
	color: #1f194f;
	height: 60px;
	min-width: 240px;
	border-radius: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 700;
	position: relative;

	a{
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.btn:not(.btn-area-cliente)::after{
	content: '';
  position: absolute;
  height: 70px;
  width: calc(100% + 10px);
  border: 2px solid #fff;
  border-radius: 35px;
	opacity: 0;
	transform: scale(0);
	transition: transform 0.3s, opacity 0.2s;
}
.btn-green::after{
	content: '';
  position: absolute;
  height: 70px;
  width: calc(100% + 10px);
  border: 2px solid color(spring-green)!important;
  border-radius: 35px;
	opacity: 0;
	transform: scale(0);
	transition: transform 0.3s, opacity 0.2s;
}
.btn-green:hover::after{
	opacity: .5;
	transform: scale(1);

}
.btn:hover::after{
	opacity: .5;
	transform: scale(1);
}
.btn-default{
	background-color: #fff;

}
.btn-green{
	background-color: color(spring-green);

	a{
		color: color(valhalla);
	}
}

.navigation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
	transition: padding .4s;

	.menu.open{
		position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(31, 25, 79, .9);
    z-index: 9;

		ul{
			display: flex;
			flex-direction: column;
			height: calc(100% - 100px);
			justify-content: space-evenly;

			a:not(.btn){
				font-size: 18px;
			}

			.btn-area-cliente{
				height: 60px;
  			border-radius: 35px;
				position: relative;
			}

		}
	}

	.menu ul{
		width: 835px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mobile-toggle{
		// display: none;
		span{
			width: 35px;
			height: 2px;
			background-color: #fff;
			display: block;

		}
		span:nth-child(1){
			transform: translateY(-10px);
		}
		span:nth-child(3){
			transform: translateY(10px);
		}
	}

		li a{
			font-weight: 700;
			font-size: 12px;
			letter-spacing: .8px;

		}
		li a:not(.btn)::after{
			display: inline-block;
			opacity: 0;
			transition: transform 0.3s, opacity 0.2s;
			margin-left: 10px;
	    content: '•';
			font-size: 21px;
	    transform: translateX(-20px);
			color: color(spring-green);
		}
		li a:not(.btn)::before{
			display: inline-block;
			opacity: 0;
			transition: transform 0.3s, opacity 0.2s;
			margin-right: 10px;
	    content: '•';
			font-size: 21px;
	    transform: translateX(20px);
			color: color(spring-green);
		}
		li a:not(.btn):hover::before{
			opacity: 1;
			transform: translateX(0px);
		}
		li a:not(.btn):hover::after{
			opacity: 1;
			transform: translateX(0px);
		}
		li a:not(.btn){
			color: #fff;
		}
}
.section-footer{
	background-color: color(valhalla);
	position: relative;

	.footer-content{
		padding: 100px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		overflow: hidden;

		header{
			font-size: 32px;
			color: #fff;
			font-weight: 900;
			width: 635px;
			max-width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			margin-bottom: 50px;
			z-index: 2;

			b{
				color: color(spring-green);
			}

      p {
        line-height: 1.2;
      }
		}
		.btns-wrapper{
			display: flex;
			z-index: 2;

			.btn{

				@media(max-width: 595px){
					margin-bottom: 15px;
				}
			}

			.btn:not(:last-child){
				margin-right: 30px;

				@media(max-width: 595px){
					margin-right: 0;
				}
			}
		}
	}
	.icon{
		position: absolute;
		z-index: 1;
	}
}
footer{
  background-color: color(valhalla);
	position: relative;
	.copy{
		background-color: #2b3391;
		color: #8e97ff;
		font-size: 14px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;

    @media (max-width: 568px) {
      text-align: center;
    }

		.container{
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			.logo-footer {
				position: absolute;
				right: 0;
			}
		}
	}
}
@media (min-width:768px){
	.mobile-toggle{
		display: none;

	}
}
@media (max-width:1024px){
	.mobile-toggle{
		height: 35px;
    width: 35px;
		display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
		z-index: 9;
	}
	.navigation .menu ul{
		display: none;
		width: 100%;
	}
}
@media (max-width:767px){
	.container-medium{
		padding: 0 30px;
	}

}
@media (max-width:425px){
	.navigation{
		padding: 15px 0;

		.logo{

			img{
				width: 150px;
			}
		}
		.mobile-toggle{
			height: 25px;
	    width: 25px;

			span{
				width: 25px;
			}

			span:first-child{
		    transform: translateY(-8px);
			}
			span:nth-child(3){
		    transform: translateY(8px);
			}

		}
	}
	.section-footer{

		.footer-content{
			padding: 50px 0;

			.btns-wrapper{
				width: 100%;
				flex-direction: column;
				padding: 15px;

				.btn{
					width: 100%;
					margin: 0;
					margin-bottom: 30px;
				}
			}
		}

	}
	footer{
		.copy{
			height: auto;
			padding: 15px 0;

			.container{
				flex-direction: column;

				.logo-footer{
					position: relative;
					margin-top: 15px;
				}
			}

		}
	}
}
@media (max-width:320px){
	footer .footer-content header{
		font-size: 23px;
	}
	footer .copy .container{
		text-align: center;
	}
}

/** @import: Globals. */
@import 'globals/header';
@import 'globals/footer';
// @import 'globals/menu';

/** @import: Elements. */
@import 'elements/general';
@import "elements/progress-screen";
// @import 'elements/buttons';
// @import 'elements/cards';

/** @import: Pages. */
@import 'pages/home';
@import 'pages/planos';
@import 'pages/sobre';
@import 'pages/como-funciona';
@import 'pages/contato';
@import 'pages/abertura';
@import 'pages/contratacao';
// @import 'pages/search';
// @import 'pages/buildings';
// @import 'pages/building';
// @import 'pages/contact';

/** @import: Pages [Mobile]. */
// @import "pages/mobile/{{page_name}}";
