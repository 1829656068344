#page-abertura{
  .loading-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: fade-out(black, .4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    span {
      display: block;
      background: white;
      border-radius: 5px;
      padding: 25px 35px;
      box-shadow: 0 2px 4px fade-out(black, .8);
    }
  }

  .section-hero-abertura{

    @media (max-width: 595px){
      height: 150px;
    }
    img{
      width: 100%;

      @media (max-width: 595px){
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section-abertura{
    padding: 100px 0;

    @media (max-width: 595px){
      padding: 50px 0;
    }

    .section-abertura-content{

      header.title{
        font-size: 48px;
        font-weight:900;
        color: color(spring-green);
        margin-bottom: 25px;
      }
      .description{

        p{
          font-size: 18px;
          line-height: 25px;
          color: #808080;
          margin-bottom: 25px;

          @media (max-width: 595px){
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }
  }
  .formulario .wrapper{

    width: 790px;
    max-width: 100%;
    margin: 0 auto;


    .titulo-sessao{
      color: #1f194f;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    .subtitulo-sessao{
      color: color(spring-green);
      font-size: 14px;
      font-weight: 700;
      line-height: 1.4;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    .row-radios{
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      padding-bottom: 7px;

      .row-title{
        color: #808080;
        margin-right: 15px;
        font-weight: 700;
      }

      .field:not(:last-child){
        margin-right: 15px;
      }
    }
    .row-radio-wrapper{
      display: flex;
      align-items: center;

      .radio-wrapper:not(:last-child){
        margin-right: 35px;
      }
    }
    .div-socio{
      margin-bottom: 30px;
    }
    .btn-wrapper{
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid color(valhalla);
    }
    .endereco-empresa-wrapper{

      .fields-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .field-group{
          width: calc(100% / 4 - 15px);
        }
      }
    }

    .field-group[data-name="capital-valor-quota"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
    .field-group[data-name="capital-valor-bens"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .field-group[data-name="valor-quota-socio1"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
    .field-group[data-name="valor-bens-socio1"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .field-group[data-name="valor-quota-socio2"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
    .field-group[data-name="valor-bens-socio2"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .field-group[data-name="valor-quota-socio3"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
    .field-group[data-name="valor-bens-socio3"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .field-group[data-name="valor-quota-socio4"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
    .field-group[data-name="valor-bens-socio4"]{
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out;

      &.show{
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }

    .documentos-necessarios-wrapper ul{

      margin-bottom: 35px;

      li{
        color: #808080;
        font-size: 14px;

        &:not(:last-child){
          margin-bottom: 15px;
        }
      }
    }
  }
  .btn-add-socio{

    transition: background-color .4s ease-in-out, color .4s ease-in-out;

    &.disabled{
      pointer-events: none;
      background-color: #808080;
      color: #bfbbbb;

    }
  }
  .formulario .form-submit{
    width: 790px;
    max-width: 100%;
    margin: 0 auto;
  }
}
