@charset "UTF-8";
/** @import: Vendor. */
@import url(../vendor/normalize/normalize.css);
@import url(../vendor/fancybox/jquery.fancybox.min.css);
@import url(../vendor/owl/owl.carousel.min.css);
/** @import: Main. */
* {
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

form input {
  line-height: initial; }

form input,
form textarea,
form select {
  outline: none !important; }
  form input:active, form input:focus,
  form textarea:active,
  form textarea:focus,
  form select:active,
  form select:focus {
    outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

/** @list: Themes. */
/** @list: Colors. */
/**
 * Returns a color from its name.
 *
 * @param  {string} $keys... Color names. Include multiple values for nested variations.
 * @return {string}          Color code.
 */
/*! Font families list */
/** @list: Transitions. */
/**
 * Returns a transition duration from a pre-defined speed.
 *
 * @param  {string} $speed Transition speed.
 * @return {Number}        Transition duration.
 */
/** @list: Timing-functions. */
/**
 * Returns a transition-timing-function setting from its type.
 *
 * @param  {string}   $type Timing-function type.
 * @return {function}       Timing-function setting.
 */
@font-face {
  font-family: 'Brandon Grotesque';
  src: url("../assets/fonts/BrandonGrotesque-Bold.woff2") format("woff2"), url("../assets/fonts/BrandonGrotesque-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Brandon Grotesque';
  src: url("../assets/fonts/BrandonGrotesque-Black.woff2") format("woff2"), url("../assets/fonts/BrandonGrotesque-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Brandon Grotesque';
  src: url("../assets/fonts/BrandonGrotesque-Regular.woff2") format("woff2"), url("../assets/fonts/BrandonGrotesque-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/** Body. */
body {
  font-family: Brandon Grotesque;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body.no-overflow {
  overflow: hidden; }

[data-split-by="word"] .w {
  display: inline-block;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.4s, transform 0.4s cubic-bezier(0.4, 0, 0, 1); }
  [data-split-by="word"] .w:nth-child(1) {
    transition-delay: 0s; }
  [data-split-by="word"] .w:nth-child(2) {
    transition-delay: 0.15s; }
  [data-split-by="word"] .w:nth-child(3) {
    transition-delay: 0.3s; }
  [data-split-by="word"] .w:nth-child(4) {
    transition-delay: 0.45s; }
  [data-split-by="word"] .w:nth-child(5) {
    transition-delay: 0.6s; }
  [data-split-by="word"] .w:nth-child(6) {
    transition-delay: 0.75s; }
  [data-split-by="word"] .w:nth-child(7) {
    transition-delay: 0.9s; }
  [data-split-by="word"] .w:nth-child(8) {
    transition-delay: 1.05s; }
  [data-split-by="word"] .w:nth-child(9) {
    transition-delay: 1.2s; }
  [data-split-by="word"] .w:nth-child(10) {
    transition-delay: 1.35s; }
  [data-split-by="word"] .w:nth-child(11) {
    transition-delay: 1.5s; }
  [data-split-by="word"] .w:nth-child(12) {
    transition-delay: 1.65s; }
  [data-split-by="word"] .w:nth-child(13) {
    transition-delay: 1.8s; }
  [data-split-by="word"] .w:nth-child(14) {
    transition-delay: 1.95s; }

[data-split-by="word"].entered .w {
  opacity: 1;
  transform: translateY(0); }

::selection {
  background-color: #2fc5f4;
  color: #fff; }

a {
  transition: 200ms ease-in-out; }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666; }

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #0eea8c; }

[type="radio"]:not(:checked) + label:before {
  background-color: #fff; }

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 6px;
  height: 6px;
  background: #fff;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.form .group-fields {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column; }
  .form .group-fields label {
    width: 100%;
    margin-bottom: 15px;
    font-size: 18px;
    color: #1f194f; }
    .form .group-fields label strong {
      color: #2fc5f4;
      margin-right: 3px; }
  .form .group-fields .fields {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .form .group-fields .fields .field-group {
      margin-bottom: 0; }

.form .field-group {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #2fc5f4;
  transition: border-color 0.5s cubic-bezier(0, 0, 0.3, 1);
  margin-bottom: 35px; }
  .form .field-group .bandeiraCartao {
    position: absolute;
    right: 0; }
  .form .field-group .field {
    color: #808080;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0 0 5px;
    z-index: 1; }
  .form .field-group .placeholder {
    color: #808080;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 2;
    transition: opacity 0.5s cubic-bezier(0, 0, 0.3, 1); }
    .form .field-group .placeholder strong {
      color: #2fc5f4;
      margin-left: 3px; }
  .form .field-group.invalid {
    border-color: red; }
  .form .field-group.not-empty, .form .field-group.focus {
    border-color: #2fc5f4; }
    .form .field-group.not-empty .placeholder, .form .field-group.focus .placeholder {
      opacity: 0; }

/** Layout. */
.container {
  width: 1200px;
  max-width: calc(100vw - 40px);
  margin: 0 auto; }

.container-medium {
  width: 970px;
  max-width: 100%;
  margin: 0 auto; }

.btn {
  color: #1f194f;
  height: 60px;
  min-width: 240px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  position: relative; }
  .btn a {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }

.btn:not(.btn-area-cliente)::after {
  content: '';
  position: absolute;
  height: 70px;
  width: calc(100% + 10px);
  border: 2px solid #fff;
  border-radius: 35px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.2s; }

.btn-green::after {
  content: '';
  position: absolute;
  height: 70px;
  width: calc(100% + 10px);
  border: 2px solid #0eea8c !important;
  border-radius: 35px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.2s; }

.btn-green:hover::after {
  opacity: .5;
  transform: scale(1); }

.btn:hover::after {
  opacity: .5;
  transform: scale(1); }

.btn-default {
  background-color: #fff; }

.btn-green {
  background-color: #0eea8c; }
  .btn-green a {
    color: #1f194f; }

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  transition: padding .4s; }
  .navigation .menu.open {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(31, 25, 79, 0.9);
    z-index: 9; }
    .navigation .menu.open ul {
      display: flex;
      flex-direction: column;
      height: calc(100% - 100px);
      justify-content: space-evenly; }
      .navigation .menu.open ul a:not(.btn) {
        font-size: 18px; }
      .navigation .menu.open ul .btn-area-cliente {
        height: 60px;
        border-radius: 35px;
        position: relative; }
  .navigation .menu ul {
    width: 835px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .navigation .mobile-toggle span {
    width: 35px;
    height: 2px;
    background-color: #fff;
    display: block; }
  .navigation .mobile-toggle span:nth-child(1) {
    transform: translateY(-10px); }
  .navigation .mobile-toggle span:nth-child(3) {
    transform: translateY(10px); }
  .navigation li a {
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .8px; }
  .navigation li a:not(.btn)::after {
    display: inline-block;
    opacity: 0;
    transition: transform 0.3s, opacity 0.2s;
    margin-left: 10px;
    content: '•';
    font-size: 21px;
    transform: translateX(-20px);
    color: #0eea8c; }
  .navigation li a:not(.btn)::before {
    display: inline-block;
    opacity: 0;
    transition: transform 0.3s, opacity 0.2s;
    margin-right: 10px;
    content: '•';
    font-size: 21px;
    transform: translateX(20px);
    color: #0eea8c; }
  .navigation li a:not(.btn):hover::before {
    opacity: 1;
    transform: translateX(0px); }
  .navigation li a:not(.btn):hover::after {
    opacity: 1;
    transform: translateX(0px); }
  .navigation li a:not(.btn) {
    color: #fff; }

.section-footer {
  background-color: #1f194f;
  position: relative; }
  .section-footer .footer-content {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden; }
    .section-footer .footer-content header {
      font-size: 32px;
      color: #fff;
      font-weight: 900;
      width: 635px;
      max-width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      z-index: 2; }
      .section-footer .footer-content header b {
        color: #0eea8c; }
      .section-footer .footer-content header p {
        line-height: 1.2; }
    .section-footer .footer-content .btns-wrapper {
      display: flex;
      z-index: 2; }
      @media (max-width: 595px) {
        .section-footer .footer-content .btns-wrapper .btn {
          margin-bottom: 15px; } }
      .section-footer .footer-content .btns-wrapper .btn:not(:last-child) {
        margin-right: 30px; }
        @media (max-width: 595px) {
          .section-footer .footer-content .btns-wrapper .btn:not(:last-child) {
            margin-right: 0; } }
  .section-footer .icon {
    position: absolute;
    z-index: 1; }

footer {
  background-color: #1f194f;
  position: relative; }
  footer .copy {
    background-color: #2b3391;
    color: #8e97ff;
    font-size: 14px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 568px) {
      footer .copy {
        text-align: center; } }
    footer .copy .container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      footer .copy .container .logo-footer {
        position: absolute;
        right: 0; }

@media (min-width: 768px) {
  .mobile-toggle {
    display: none; } }

@media (max-width: 1024px) {
  .mobile-toggle {
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9; }
  .navigation .menu ul {
    display: none;
    width: 100%; } }

@media (max-width: 767px) {
  .container-medium {
    padding: 0 30px; } }

@media (max-width: 425px) {
  .navigation {
    padding: 15px 0; }
    .navigation .logo img {
      width: 150px; }
    .navigation .mobile-toggle {
      height: 25px;
      width: 25px; }
      .navigation .mobile-toggle span {
        width: 25px; }
      .navigation .mobile-toggle span:first-child {
        transform: translateY(-8px); }
      .navigation .mobile-toggle span:nth-child(3) {
        transform: translateY(8px); }
  .section-footer .footer-content {
    padding: 50px 0; }
    .section-footer .footer-content .btns-wrapper {
      width: 100%;
      flex-direction: column;
      padding: 15px; }
      .section-footer .footer-content .btns-wrapper .btn {
        width: 100%;
        margin: 0;
        margin-bottom: 30px; }
  footer .copy {
    height: auto;
    padding: 15px 0; }
    footer .copy .container {
      flex-direction: column; }
      footer .copy .container .logo-footer {
        position: relative;
        margin-top: 15px; } }

@media (max-width: 320px) {
  footer .footer-content header {
    font-size: 23px; }
  footer .copy .container {
    text-align: center; } }

/** @import: Globals. */
#main-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  transition: background-color 0.7s cubic-bezier(0.5, 0, 0, 1);
  /** Menu open. */
  /** Sticky and single. */ }
  #main-header .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 45px 0;
    transition: padding 0.7s cubic-bezier(0.5, 0, 0, 1); }
    #main-header .flex-wrapper .logo {
      position: relative;
      margin: 0;
      padding: 0;
      pointer-events: initial; }
      #main-header .flex-wrapper .logo a {
        display: inline-block; }
    #main-header .flex-wrapper .menu-toggler {
      position: relative;
      display: flex;
      align-items: center;
      height: 32px;
      margin: 0;
      padding: 0;
      pointer-events: initial;
      transition: opacity 0.7s cubic-bezier(0.5, 0, 0, 1), visibility 0.7s cubic-bezier(0.5, 0, 0, 1); }
      #main-header .flex-wrapper .menu-toggler--border-top {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px; }
        #main-header .flex-wrapper .menu-toggler--border-top::before, #main-header .flex-wrapper .menu-toggler--border-top::after {
          content: '';
          background-color: white;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 2px;
          z-index: 1;
          transition: transform 1s cubic-bezier(0.5, 0, 0, 1); }
        #main-header .flex-wrapper .menu-toggler--border-top::before {
          transform-origin: 0% 50%;
          transform: scaleX(0.25); }
        #main-header .flex-wrapper .menu-toggler--border-top::after {
          transform-origin: 100% 50%;
          transform: scaleX(0.6); }
      #main-header .flex-wrapper .menu-toggler--border-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 2px; }
        #main-header .flex-wrapper .menu-toggler--border-bottom::before, #main-header .flex-wrapper .menu-toggler--border-bottom::after {
          content: '';
          background-color: white;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 2px;
          transition: transform 1s cubic-bezier(0.5, 0, 0, 1);
          z-index: 1; }
        #main-header .flex-wrapper .menu-toggler--border-bottom::before {
          transform-origin: 0% 50%;
          transform: scaleX(0.6); }
        #main-header .flex-wrapper .menu-toggler--border-bottom::after {
          transform-origin: 100% 50%;
          transform: scaleX(0.25); }
      #main-header .flex-wrapper .menu-toggler span:nth-child(2) {
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 1.5px;
        margin: 0;
        padding: 0; }
      #main-header .flex-wrapper .menu-toggler:hover .menu-toggler--border-top::after {
        transform: scaleX(1); }
      #main-header .flex-wrapper .menu-toggler:hover .menu-toggler--border-top::before {
        transform: scaleX(0); }
      #main-header .flex-wrapper .menu-toggler:hover .menu-toggler--border-bottom::after {
        transform: scaleX(0); }
      #main-header .flex-wrapper .menu-toggler:hover .menu-toggler--border-bottom::before {
        transform: scaleX(1); }
    #main-header .flex-wrapper .cta {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      pointer-events: initial; }
      #main-header .flex-wrapper .cta button {
        position: relative;
        margin: 0;
        padding: 0;
        cursor: pointer; }
        #main-header .flex-wrapper .cta button svg path {
          fill: white; }
        #main-header .flex-wrapper .cta button:not(:last-child) {
          margin-right: 40px; }
  #main-header.menu-open .flex-wrapper .menu-toggler {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  #main-header.sticky .navigation {
    padding: 20px 0;
    transition: padding .4s; }
  #main-header.sticky, #main-header.single {
    pointer-events: initial;
    background-color: #060A32; }
    #main-header.sticky .flex-wrapper, #main-header.single .flex-wrapper {
      padding: 15px 0; }

#main-footer {
  background-color: #060A32;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 60px 0 45px; }
  #main-footer .flex-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0; }
    #main-footer .flex-wrapper:nth-child(2) {
      margin-top: 60px;
      padding-left: 250px; }
    #main-footer .flex-wrapper .column {
      position: relative;
      margin: 0;
      padding: 0; }
      #main-footer .flex-wrapper .column .logo {
        position: relative;
        margin: 0;
        padding: 0;
        pointer-events: initial; }
        #main-footer .flex-wrapper .column .logo a {
          display: inline-block; }
      #main-footer .flex-wrapper .column .navigation {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0; }
        #main-footer .flex-wrapper .column .navigation ul {
          position: relative;
          width: 100%;
          margin: 0 0 50px;
          padding: 0; }
          #main-footer .flex-wrapper .column .navigation ul li {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0; }
            #main-footer .flex-wrapper .column .navigation ul li:not(:last-child) {
              margin-bottom: 13px; }
            #main-footer .flex-wrapper .column .navigation ul li a {
              color: #8089FF;
              font-size: 12px;
              font-weight: normal;
              text-transform: uppercase;
              margin: 0;
              padding: 0;
              transition: color 0.7s cubic-bezier(0.5, 0, 0, 1); }
              #main-footer .flex-wrapper .column .navigation ul li a:hover {
                color: white; }
      #main-footer .flex-wrapper .column .contact {
        position: relative;
        width: 100%;
        margin: 0 0 60px;
        padding: 0; }
        #main-footer .flex-wrapper .column .contact-title {
          color: white;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          padding: 0; }
        #main-footer .flex-wrapper .column .contact-phone {
          position: relative;
          display: flex;
          align-items: center;
          margin: 30px 0 0;
          padding: 0; }
          #main-footer .flex-wrapper .column .contact-phone--icon {
            position: relative;
            margin: 0 20px 0 0;
            padding: 0; }
            #main-footer .flex-wrapper .column .contact-phone--icon svg path {
              fill: #7780F1; }
          #main-footer .flex-wrapper .column .contact-phone--content {
            position: relative;
            margin: 0;
            padding: 0; }
            #main-footer .flex-wrapper .column .contact-phone--content span {
              color: white;
              position: relative;
              display: inline-block; }
              #main-footer .flex-wrapper .column .contact-phone--content span:nth-child(1) {
                top: -8px;
                font-size: 18px;
                margin-right: 10px; }
              #main-footer .flex-wrapper .column .contact-phone--content span:nth-child(2) {
                font-size: 28px; }
      #main-footer .flex-wrapper .column .social {
        position: relative;
        margin: 0;
        padding: 0; }
        #main-footer .flex-wrapper .column .social ul {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0; }
          #main-footer .flex-wrapper .column .social ul li {
            position: relative;
            margin: 0;
            padding: 0; }
            #main-footer .flex-wrapper .column .social ul li:not(:last-child) {
              margin-right: 40px; }
            #main-footer .flex-wrapper .column .social ul li a {
              display: inline-block;
              margin: 0;
              padding: 0; }
              #main-footer .flex-wrapper .column .social ul li a svg path {
                fill: white; }
      #main-footer .flex-wrapper .column .newsletter {
        position: relative;
        width: 370px;
        margin: 0;
        padding: 0; }
        #main-footer .flex-wrapper .column .newsletter-title {
          color: white;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0;
          padding: 0; }
        #main-footer .flex-wrapper .column .newsletter-subtitle {
          color: #8089FF;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          margin: 15px 0 0;
          padding: 0; }
        #main-footer .flex-wrapper .column .newsletter-form {
          position: relative;
          width: 100%;
          margin: 35px 0 0;
          padding: 0; }
          #main-footer .flex-wrapper .column .newsletter-form .form {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 0; }
            #main-footer .flex-wrapper .column .newsletter-form .form .field-group {
              position: relative;
              width: 100%;
              margin: 0;
              padding: 0; }
              #main-footer .flex-wrapper .column .newsletter-form .form .field-group .placeholder {
                color: white;
                font-size: 12px;
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                position: absolute;
                top: 4px;
                left: 0;
                transition: opacity 0.7s cubic-bezier(0.5, 0, 0, 1); }
              #main-footer .flex-wrapper .column .newsletter-form .form .field-group .field {
                background-color: transparent;
                color: white;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                padding: 0 0 10px;
                display: block;
                width: 100%;
                border: none;
                border-bottom: 1px solid #8089FF;
                transition: border-color 0.7s cubic-bezier(0.5, 0, 0, 1); }
              #main-footer .flex-wrapper .column .newsletter-form .form .field-group.focus .placeholder, #main-footer .flex-wrapper .column .newsletter-form .form .field-group.not-empty .placeholder {
                opacity: 0; }
              #main-footer .flex-wrapper .column .newsletter-form .form .field-group.invalid .field {
                border-color: red; }
            #main-footer .flex-wrapper .column .newsletter-form .form .form-submit {
              position: relative;
              margin: 0 0 0 15px;
              padding: 0; }
              #main-footer .flex-wrapper .column .newsletter-form .form .form-submit button {
                position: relative;
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0; }
                #main-footer .flex-wrapper .column .newsletter-form .form .form-submit button span {
                  color: white;
                  font-size: 12px;
                  font-weight: bold;
                  text-transform: uppercase;
                  display: inline-block;
                  margin: 0 10px 0 0;
                  padding: 0; }
                #main-footer .flex-wrapper .column .newsletter-form .form .form-submit button svg {
                  position: relative;
                  margin: 0;
                  padding: 0; }
                  #main-footer .flex-wrapper .column .newsletter-form .form .form-submit button svg path {
                    fill: #8089FF; }
    #main-footer .flex-wrapper .copyright {
      color: #808080;
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      padding: 0; }
    #main-footer .flex-wrapper .assinatura {
      position: relative;
      margin: 0 0 0 35px;
      padding: 0; }
      #main-footer .flex-wrapper .assinatura a {
        display: inline-block;
        margin: 0;
        padding: 0; }

/** @import: Elements. */
/** Hide svg. */
.svg-hide {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }

.form .placeholder {
  pointer-events: none; }

.box-status {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: visibility .4s, opacity .4s; }
  .box-status .box {
    padding: 50px 100px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 665px;
    max-width: 100%;
    box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5); }
    .box-status .box header {
      font-size: 36px;
      font-weight: 900;
      color: #ff284b;
      margin-bottom: 20px; }
    .box-status .box p {
      font-size: 22px;
      color: #4c4c4c;
      line-height: 26px; }
    .box-status .box .btn-fechar-box {
      margin: 25px auto 0; }
  .box-status.show {
    visibility: visible;
    opacity: 1; }

.screen-progress {
  background-color: #fff;
  border-radius: 5px;
  color: #0eea8c;
  text-align: center;
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: .3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: #0eea8c;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: .5s; }

/** @import: Pages. */
#page-home a:not(.btn):hover {
  color: #0eea8c !important; }

.btn-area-cliente {
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700; }

.btn-area-cliente::after {
  content: '';
  position: absolute;
  height: 50px;
  width: calc(100% + 10px);
  border: 2px solid #fff;
  border-radius: 35px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.3s; }

.btn-area-cliente:hover::after {
  opacity: .5;
  transform: scale(1); }

.section-hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 60px;
  margin-bottom: 150px; }
  .section-hero::before, .section-hero::after {
    content: '';
    width: 2px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    pointer-events: none;
    clip-path: polygon(0 -150%, 100% -150%, 100% 0, 0 0);
    margin-bottom: -3px; }
  .section-hero::before {
    background-color: white;
    animation: home_hero_scroll_line 2s cubic-bezier(0.5, 0, 0, 1) infinite;
    animation-delay: .3s;
    z-index: 5; }
  .section-hero::after {
    background-color: #0eea8c;
    animation: home_hero_scroll_line 2s cubic-bezier(0.5, 0, 0, 1) infinite;
    z-index: 4;
    /** Animation keyframes. */ }

@keyframes home_hero_scroll_line {
  0% {
    clip-path: polygon(0 -150%, 100% -150%, 100% 0, 0 0); }
  80%,
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 350%, 0 350%); } }
  .section-hero .img-hero {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
    pointer-events: none;
    user-select: none; }
  .section-hero .scrolldown {
    position: absolute;
    bottom: 75px;
    left: calc(50% - 10px); }
  .section-hero .section-hero-container {
    position: relative; }
    .section-hero .section-hero-container header h1 {
      font-size: 64px;
      color: #fff;
      font-weight: 900;
      width: 465px;
      max-width: 100%;
      transition: 200ms ease-in-out;
      opacity: 0; }
    .section-hero .section-hero-container span .w:last-child {
      font-size: 96px;
      margin-top: 25px;
      position: relative;
      left: -5px; }
      @media (max-width: 595px) {
        .section-hero .section-hero-container span .w:last-child {
          font-size: 72px; } }
    .section-hero .section-hero-container .bnt-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 60px; }
      .section-hero .section-hero-container .bnt-wrapper .btn:not(:last-child) {
        margin-right: 30px; }

.section-middle {
  position: relative; }
  @media (max-width: 568px) {
    .section-middle {
      margin-top: 60px; } }
  .section-middle .shape {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden; }
    .section-middle .shape img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .section-middle > section {
    position: relative;
    z-index: 2; }

.section-contabilidade .container {
  width: 1170px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.section-contabilidade .section-contabilidade-container {
  padding-bottom: 160px; }
  .section-contabilidade .section-contabilidade-container header {
    font-size: 56px;
    color: #0eea8c;
    font-weight: 900;
    width: 470px;
    max-width: 100%;
    margin-bottom: 65px; }
    @media (max-width: 568px) {
      .section-contabilidade .section-contabilidade-container header {
        font-size: 38px !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-bottom: 40px; } }
  .section-contabilidade .section-contabilidade-container .contabilidade-content {
    display: flex; }
    .section-contabilidade .section-contabilidade-container .contabilidade-content .left {
      width: calc(50% + 100px);
      padding: 115px 100px;
      padding-right: 200px;
      font-size: 18px;
      background-color: #e0eff4;
      position: relative;
      z-index: 1; }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .left p {
        line-height: 25px;
        color: #4c4c4c; }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .left p:not(:last-child) {
        margin-bottom: 25px; }
    .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 2;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image {
        margin-top: -100px;
        width: 600px;
        height: 490px; }
        .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image img {
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.27);
          width: 100%;
          height: 100%;
          object-fit: cover; }

.section-vantagens {
  padding-bottom: 160px; }
  .section-vantagens header {
    font-size: 56px;
    color: #0eea8c;
    font-weight: 900;
    max-width: 100%;
    margin-bottom: 90px;
    text-align: center; }
    @media (max-width: 568px) {
      .section-vantagens header {
        font-size: 38px;
        text-align: left;
        margin-bottom: 50px;
        margin-top: 50px; } }
    .section-vantagens header span {
      display: flex;
      flex-direction: column; }
  .section-vantagens .vantagens-content {
    display: flex;
    align-items: center;
    justify-content: center; }
    .section-vantagens .vantagens-content .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% / 3); }
      .section-vantagens .vantagens-content .item img {
        width: 75px;
        margin-bottom: 25px; }
      .section-vantagens .vantagens-content .item p {
        font-size: 24px;
        font-weight: 700;
        color: #1f194f;
        padding: 0 48px;
        line-height: 1.4;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; }
    .section-vantagens .vantagens-content .item:first-child p {
      padding: 0 30px; }

.section-contrate {
  padding: 160px 0; }
  .section-contrate .section-contrate-container .container {
    background-color: #0eea8c;
    height: 585px;
    display: flex;
    align-items: flex-end; }
    .section-contrate .section-contrate-container .container .contrate-content {
      display: flex;
      position: relative; }
    .section-contrate .section-contrate-container .container .left {
      width: 50%;
      z-index: 2; }
      .section-contrate .section-contrate-container .container .left img {
        margin-bottom: -3px; }
    .section-contrate .section-contrate-container .container .right {
      width: calc(50% + 50px);
      position: absolute;
      right: 0;
      top: 90px;
      z-index: 1; }
      .section-contrate .section-contrate-container .container .right .box {
        background-color: #1f194f;
        color: #fff;
        padding: 130px 60px; }
        .section-contrate .section-contrate-container .container .right .box header {
          font-size: 36px;
          font-weight: 900;
          margin-bottom: 35px;
          text-align: center; }
        .section-contrate .section-contrate-container .container .right .box .price {
          padding: 30px;
          border: 1px solid #2fc5f4;
          border-radius: 15px;
          position: relative;
          text-align: center;
          width: 70%;
          margin: 0 auto 35px; }
        .section-contrate .section-contrate-container .container .right .box .price span {
          font-weight: 900; }
        .section-contrate .section-contrate-container .container .right .box .price .moeda {
          font-size: 32px; }
        .section-contrate .section-contrate-container .container .right .box .price .valor {
          font-size: 63px; }
        .section-contrate .section-contrate-container .container .right .box .price .centavos {
          font-size: 32px;
          position: absolute; }
        .section-contrate .section-contrate-container .container .right .box .price .perido {
          font-size: 14px;
          color: #0eea8c;
          display: block; }
        .section-contrate .section-contrate-container .container .right .box .call-to-action {
          text-align: center; }
          .section-contrate .section-contrate-container .container .right .box .call-to-action .btn-conheca-planos {
            line-height: 60px;
            display: inline-block;
            width: auto;
            min-width: none;
            padding: 0 30px; }
            .section-contrate .section-contrate-container .container .right .box .call-to-action .btn-conheca-planos::after {
              display: none; }
            .section-contrate .section-contrate-container .container .right .box .call-to-action .btn-conheca-planos:hover {
              background-color: white; }

.section-duvidas {
  background: url(../assets/images/home/duvidas.png) no-repeat center top;
  background-size: cover;
  padding-bottom: 100px; }
  .section-duvidas .section-duvidas-container header {
    font-size: 56px;
    color: #0eea8c;
    font-weight: 900;
    max-width: 100%;
    margin-bottom: 35px; }
  .section-duvidas .section-duvidas-container p.subtitle {
    font-size: 18px;
    line-height: 25px;
    color: #1f194f;
    width: 430px;
    max-width: 100%;
    margin-bottom: 50px; }
    .section-duvidas .section-duvidas-container p.subtitle a {
      font-weight: 700;
      text-decoration: underline !important;
      color: #1f194f; }
  .section-duvidas .section-duvidas-container .duvidas-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .section-duvidas .section-duvidas-container .duvidas-content .right {
      width: calc(45% - 15px); }
    .section-duvidas .section-duvidas-container .duvidas-content .left {
      margin-right: 30px;
      width: calc(55% - 15px); }
    .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-radius: 4px;
      padding: 0 30px; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li:hover {
        cursor: pointer; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li button {
        font-weight: 700;
        color: #1f194f;
        font-size: 18px;
        display: flex;
        text-align: left; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li button span {
          margin-right: 10px; }
    .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li.active {
      background-color: #2fc5f4;
      transition: background-color 0.4s; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li.active span {
        color: #fff; }
    .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li.active:hover {
      background-color: #0eea8c; }
    .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content {
      padding: 30px;
      border: 1px solid #2fc5f4;
      border-radius: 16px;
      position: relative;
      height: 485px; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: opacity .4s,visibility .4s;
        padding: 30px; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane p, .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane li, .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane ol {
          font-size: 18px;
          line-height: 25px;
          color: #4c4c4c; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane p:not(:last-child), .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane li:not(:last-child), .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane ol:not(:last-child) {
          margin-bottom: 15px; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane b, .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane strong {
          color: #1f194f; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane a {
          color: #1f194f;
          font-weight: 700;
          text-decoration: underline !important;
          line-height: 26px; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .duvidas-pane.active {
        visibility: visible;
        opacity: 1; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content ul {
        list-style-type: initial;
        position: relative; }
        .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content ul li {
          margin-bottom: 20px; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .atendimento .item h6 {
        font-size: 18px;
        color: #1f194f;
        font-weight: 700;
        margin-bottom: 5px; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .atendimento .item a {
        color: #4c4c4c;
        font-weight: 700;
        text-decoration: underline !important; }
      .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation-content .atendimento .item:not(:last-child) {
        margin-bottom: 25px; }

@media (max-width: 1024px) {
  .section-middle {
    overflow: hidden; } }

@media (min-width: 426px) and (max-width: 768px) {
  .container-medium {
    padding: 0 30px; }
  .section-contabilidade .section-contabilidade-container {
    padding-bottom: 0; }
    .section-contabilidade .section-contabilidade-container .contabilidade-content {
      flex-direction: column; }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .left, .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
        width: 100%; }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .left {
        padding: 50px; }
      .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
        position: relative;
        transform: initial; }
        .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image {
          margin-top: 0;
          width: 100%;
          height: auto; }
          .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image img {
            width: 100%; }
  .section-vantagens {
    padding-bottom: 0; }
    .section-vantagens .vantagens-content {
      flex-direction: column; }
      .section-vantagens .vantagens-content .item {
        width: 100%;
        margin-bottom: 30px; }
  .section-contrate {
    padding-bottom: 0; }
    .section-contrate .section-contrate-container {
      padding-bottom: 60px; }
      .section-contrate .section-contrate-container .container .contrate-content {
        flex-direction: column; }
        .section-contrate .section-contrate-container .container .contrate-content .left {
          width: 100%; }
          .section-contrate .section-contrate-container .container .contrate-content .left .featured-image {
            display: none; }
        .section-contrate .section-contrate-container .container .contrate-content .right {
          position: relative;
          width: 100%;
          top: -130px; } }

@media (min-width: 426px) and (max-width: 650px) {
  .section-duvidas .section-duvidas-container .duvidas-content {
    flex-direction: column; }
    .section-duvidas .section-duvidas-container .duvidas-content .left, .section-duvidas .section-duvidas-container .duvidas-content .right {
      width: 100%; }
    .section-duvidas .section-duvidas-container .duvidas-content .left ul {
      margin-bottom: 35px; } }

@media (max-width: 425px) {
  section.section header {
    font-size: 32px !important; }
  .section-hero {
    margin-bottom: 0;
    padding-bottom: 100px; }
    .section-hero .scrolldown {
      display: none; }
  .section-hero::after, .section-hero::before {
    display: none; }
  .section-hero .img-hero {
    height: 100vh; }
  .section-hero .section-hero-container header h1 {
    font-size: 45px; }
  .section-hero .section-hero-container header h1 span b {
    font-size: 70px; }
  .section-hero .section-hero-container .scrolldown {
    display: none; }
  .bnt-wrapper {
    flex-direction: column; }
    .bnt-wrapper button {
      margin: 0;
      margin-bottom: 15px;
      width: 100%; }
    .bnt-wrapper button:not(:last-child) {
      margin-right: 0 !important; }
  .section-middle {
    overflow: hidden; }
  .section-contabilidade .section-contabilidade-container {
    padding-bottom: 60px; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .left {
    width: 100%;
    padding: 30px; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
    width: 100%;
    position: relative; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image {
    margin-top: 0;
    width: 100%;
    height: auto; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image img {
    width: 100%; }
  .section-vantagens {
    padding-bottom: 60px; }
  .section-vantagens .vantagens-content {
    flex-direction: column; }
  .section-vantagens .vantagens-content .item {
    margin-bottom: 35px;
    width: 100%; }
  .section-contrate .section-contrate-container .container {
    height: auto; }
  .section-contrate .section-contrate-container .container .right {
    position: relative;
    width: 100%;
    top: -60px; }
  .section-contrate .section-contrate-container .container .right .box {
    padding: 50px 30px; }
  .section-contrate .section-contrate-container .container .right .box .price {
    width: 100%; }
  .section-contrate .section-contrate-container .container .right .box .price .moeda {
    font-size: 24px; }
  .section-contrate .section-contrate-container .container .right .box .price .valor {
    font-size: 60px; }
  .section-contrate .section-contrate-container .container .right .box .price .centavos {
    font-size: 28px; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content {
    flex-direction: column-reverse; }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
    transform: initial; }
  .section-contrate {
    padding: 60px 0; }
  .section-contrate .section-contrate-container .container .contrate-content {
    flex-direction: column; }
  .section-duvidas .section-duvidas-container .duvidas-content {
    flex-direction: column; }
  .section-duvidas .section-duvidas-container .duvidas-content .left {
    width: 100%;
    margin: 0; }
  .section-contrate .section-contrate-container .container .left img {
    display: none; }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation {
    margin-bottom: 35px; }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li {
    margin-bottom: 10px;
    border: 1px solid #1f194f; }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li.active {
    border: 0; }
  .section-duvidas .section-duvidas-container .duvidas-content .right {
    width: 100%; } }

@media (min-width: 320px) and (max-width: 375px) {
  .container-medium {
    padding: 0 15px; }
  .section-hero {
    padding-bottom: 40px; }
    .section-hero .bnt-wrapper {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 25px; }
      .section-hero .bnt-wrapper .btn {
        width: 100%;
        margin: 0;
        margin-bottom: 25px; }
        .section-hero .bnt-wrapper .btn:not(last-child) {
          margin-right: 0 !important; }
  .section-hero .scrolldown {
    display: none; }
  .section-contrate .section-contrate-container .container .right .box .price .moeda {
    font-size: 24px; }
  .section-contrate .section-contrate-container .container .right .box .price .valor {
    font-size: 34px; }
  .section-contrate .section-contrate-container .container .right .box .price .centavos {
    font-size: 21px;
    position: relative; }
  .section-contrate .section-contrate-container .container .right .box .btn-conheca-planos {
    min-width: 195px; }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li {
    padding: 0 15px; }
  .section-duvidas .section-duvidas-container .duvidas-navigation-content {
    padding: 30px 15px; }
    .section-duvidas .section-duvidas-container .duvidas-navigation-content ul li {
      margin-bottom: 35px !important;
      font-size: 16px !important; }
    .section-duvidas .section-duvidas-container .duvidas-navigation-content .atendimento .item h6 {
      font-size: 16px; }
    .section-duvidas .section-duvidas-container .duvidas-navigation-content .atendimento .item p {
      font-size: 16px; } }

.section-hero .headline {
  color: white;
  font-weight: bold;
  max-width: 50%; }
  @media (max-width: 568px) {
    .section-hero .headline {
      text-align: center;
      max-width: none; } }
  .section-hero .headline .line {
    display: block; }
    .section-hero .headline .line:not(:last-child) {
      margin-bottom: 40px;
      letter-spacing: 1px; }
      @media (max-width: 568px) {
        .section-hero .headline .line:not(:last-child) {
          margin-bottom: 30px; } }
    .section-hero .headline .line-1 {
      font-size: 64px; }
      @media (max-width: 568px) {
        .section-hero .headline .line-1 {
          font-size: 42px; } }
    .section-hero .headline .line-2 {
      font-size: 96px; }
      @media (max-width: 568px) {
        .section-hero .headline .line-2 {
          font-size: 56px; } }

.section-hero .button {
  text-transform: uppercase;
  display: inline-block;
  margin-top: 50px; }
  .section-hero .button .btn {
    min-width: none;
    padding: 0 50px; }
  @media (max-width: 568px) {
    .section-hero .button {
      display: flex;
      align-items: center;
      justify-content: center; } }

#page-planos .plans--navigation {
  border-bottom: 1px solid #2fc5f4;
  display: flex; }
  @media (max-width: 568px) {
    #page-planos .plans--navigation {
      border: none; } }
  #page-planos .plans--navigation .item {
    width: 16.67%; }
    #page-planos .plans--navigation .item button {
      color: rgba(0, 0, 0, 0.2);
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;
      padding: 4px 20px 0;
      transition: color .15s, background-color .15s; }
      #page-planos .plans--navigation .item button:hover {
        color: #1f194f;
        transition: none; }
    #page-planos .plans--navigation .item.active button {
      color: #1f194f;
      background-color: #0eea8c; }
  #page-planos .plans--navigation .mobile-select {
    width: 100%;
    position: relative; }
    #page-planos .plans--navigation .mobile-select select {
      appearance: none;
      background-color: white;
      border: 1px solid #2fc5f4;
      display: block;
      width: 100%;
      height: 50px;
      padding: 2px 14px 0; }
    #page-planos .plans--navigation .mobile-select::after {
      content: '';
      border-top: 6px solid #1f194f;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -2px; }

#page-planos .plans--content {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 768px) {
    #page-planos .plans--content {
      display: block; } }
  #page-planos .plans--content .tabs {
    width: 100%;
    position: relative; }
    #page-planos .plans--content .tabs .tab {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding-right: 40px;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s, visibility .25s; }
      @media (max-width: 768px) {
        #page-planos .plans--content .tabs .tab {
          padding: 0; } }
      #page-planos .plans--content .tabs .tab.active {
        opacity: 1;
        visibility: visible; }
      #page-planos .plans--content .tabs .tab .row {
        padding: 30px 0; }
        #page-planos .plans--content .tabs .tab .row:not(:last-child) {
          border-bottom: 1px solid #2fc5f4; }
        #page-planos .plans--content .tabs .tab .row--title {
          color: #1f194f;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          display: block;
          margin-bottom: 15px; }
        #page-planos .plans--content .tabs .tab .row-plan_description .row--content p {
          line-height: 1.4; }
          #page-planos .plans--content .tabs .tab .row-plan_description .row--content p:not(:last-child) {
            margin-bottom: 12px; }
        #page-planos .plans--content .tabs .tab .row-plan_settings .row--content {
          display: flex; }
          @media (max-width: 768px) {
            #page-planos .plans--content .tabs .tab .row-plan_settings .row--content {
              display: block; } }
        #page-planos .plans--content .tabs .tab .row-plan_settings .option {
          width: calc(33.33% - 20px); }
          #page-planos .plans--content .tabs .tab .row-plan_settings .option:not(:last-child) {
            margin-right: 30px; }
          @media (max-width: 768px) {
            #page-planos .plans--content .tabs .tab .row-plan_settings .option {
              width: 100%;
              margin: 0; }
              #page-planos .plans--content .tabs .tab .row-plan_settings .option:not(:last-child) {
                margin-bottom: 45px; } }
          #page-planos .plans--content .tabs .tab .row-plan_settings .option .label {
            color: #1f194f;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.4;
            text-transform: uppercase;
            height: 38px;
            margin-bottom: 15px; }
            @media (max-width: 768px) {
              #page-planos .plans--content .tabs .tab .row-plan_settings .option .label {
                height: auto; } }
          #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-select {
            position: relative; }
            #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-select select {
              appearance: none;
              background-color: white;
              border: 1px solid #2fc5f4;
              display: block;
              width: 100%;
              height: 50px;
              padding: 2px 14px 0; }
            #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-select::after {
              content: '';
              border-top: 6px solid #1f194f;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              position: absolute;
              right: 15px;
              top: 50%;
              margin-top: -2px;
              pointer-events: none; }
          #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio {
            height: 40px;
            padding-top: 4px; }
            #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio {
              display: flex;
              align-items: center; }
              #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio:not(:last-child) {
                margin-bottom: 10px; }
              #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio input {
                display: none; }
              #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio label {
                display: flex;
                align-items: center;
                cursor: pointer; }
                #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio label::before {
                  width: 14px;
                  height: 14px; }
                #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio label::after {
                  top: 5px;
                  left: 5px; }
                #page-planos .plans--content .tabs .tab .row-plan_settings .option .field-radio .radio label .text {
                  position: relative;
                  top: 1px; }
        #page-planos .plans--content .tabs .tab .row-plan_features ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 30px; }
          @media (max-width: 768px) {
            #page-planos .plans--content .tabs .tab .row-plan_features ul {
              display: block; } }
          #page-planos .plans--content .tabs .tab .row-plan_features ul li {
            display: flex;
            align-items: flex-start; }
            #page-planos .plans--content .tabs .tab .row-plan_features ul li:not(:last-child) {
              margin-bottom: 12px; }
            #page-planos .plans--content .tabs .tab .row-plan_features ul li span {
              display: block; }
            #page-planos .plans--content .tabs .tab .row-plan_features ul li .icon {
              position: relative;
              top: 1px;
              margin-right: 10px; }
            #page-planos .plans--content .tabs .tab .row-plan_features ul li .text {
              line-height: 1.4; }
  #page-planos .plans--content .price-box {
    color: white;
    background-color: #1f194f;
    border-radius: 10px;
    width: 370px;
    flex-shrink: 0;
    margin-top: 30px;
    padding: 70px 50px; }
    @media (max-width: 768px) {
      #page-planos .plans--content .price-box {
        width: 100%;
        padding: 40px 20px; } }
    #page-planos .plans--content .price-box .headline {
      font-weight: bold;
      font-size: 42px;
      text-align: center;
      display: block;
      margin-bottom: 30px; }
      @media (max-width: 320px) {
        #page-planos .plans--content .price-box .headline {
          font-size: 32px; } }
    #page-planos .plans--content .price-box .price-display {
      position: relative; }
      #page-planos .plans--content .price-box .price-display .from {
        color: #0eea8c;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        display: block;
        margin-bottom: 8px; }
      #page-planos .plans--content .price-box .price-display .price {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        #page-planos .plans--content .price-box .price-display .price .loading-icon {
          display: block;
          width: 31px;
          position: absolute;
          top: 50%;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);
          pointer-events: none;
          transition: opacity .25s; }
          #page-planos .plans--content .price-box .price-display .price .loading-icon::before {
            content: '';
            display: block;
            padding-top: 100%; }
          #page-planos .plans--content .price-box .price-display .price .loading-icon svg {
            animation: vig_loader__rotate 1.8s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            /** Animation keyframes. */ }

@keyframes vig_loader__rotate {
  100% {
    transform: rotate(360deg); } }
            #page-planos .plans--content .price-box .price-display .price .loading-icon svg circle {
              fill: none;
              stroke: white;
              stroke-width: 3px;
              stroke-dasharray: 1, 200;
              stroke-dashoffset: 0;
              animation: vig_loader__dash 1.5s ease-in-out infinite;
              stroke-linecap: round;
              /** Animation keyframes. */ }

@keyframes vig_loader__dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }
        #page-planos .plans--content .price-box .price-display .price span {
          transition: opacity .25s; }
        #page-planos .plans--content .price-box .price-display .price.loading .loading-icon {
          opacity: 1; }
        #page-planos .plans--content .price-box .price-display .price.loading span {
          opacity: .1; }
        #page-planos .plans--content .price-box .price-display .price-prefix, #page-planos .plans--content .price-box .price-display .price-decimals {
          font-size: 32px;
          position: relative; }
          @media (max-width: 320px) {
            #page-planos .plans--content .price-box .price-display .price-prefix, #page-planos .plans--content .price-box .price-display .price-decimals {
              font-size: 24px; } }
        #page-planos .plans--content .price-box .price-display .price-prefix {
          top: 10px;
          margin-right: 4px; }
        #page-planos .plans--content .price-box .price-display .price-main {
          font-size: 62px;
          font-weight: 900; }
          @media (max-width: 320px) {
            #page-planos .plans--content .price-box .price-display .price-main {
              font-size: 50px; } }
        #page-planos .plans--content .price-box .price-display .price-decimals {
          top: -10px; }
          #page-planos .plans--content .price-box .price-display .price-decimals::before {
            content: ','; }
      #page-planos .plans--content .price-box .price-display .payment-info {
        color: #0eea8c;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        display: block;
        margin-top: 6px; }
    #page-planos .plans--content .price-box .select-plan-action {
      text-align: center;
      margin-top: 30px; }
      #page-planos .plans--content .price-box .select-plan-action .select-plan {
        color: #1f194f;
        background-color: #0eea8c;
        border-radius: 30px;
        font-size: 14px;
        font-weight: bold;
        line-height: 60px;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        height: 63px;
        padding: 0 42px;
        transition: background-color .25s; }
        @media (max-width: 320px) {
          #page-planos .plans--content .price-box .select-plan-action .select-plan {
            height: 50px;
            border-radius: 25px;
            line-height: 52px; } }
        #page-planos .plans--content .price-box .select-plan-action .select-plan:hover {
          background-color: white; }

#page-planos .section-hero-planos img {
  width: 100%; }

#page-planos .section-planos {
  margin: 60px 0 150px; }
  #page-planos .section-planos .section-planos-content header.title {
    font-size: 72px;
    color: #0eea8c;
    font-weight: 900;
    margin-bottom: 70px; }
  #page-planos .section-planos .section-planos-content ul.planos-navigation {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #2fc5f4;
    margin-bottom: 25px; }
    #page-planos .section-planos .section-planos-content ul.planos-navigation li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 6);
      text-align: center;
      line-height: 21px; }
      #page-planos .section-planos .section-planos-content ul.planos-navigation li button {
        width: 100%;
        height: 90px;
        color: #cccccc;
        font-weight: 900;
        pointer-events: cursor;
        text-transform: uppercase;
        padding: 0 15px; }
    #page-planos .section-planos .section-planos-content ul.planos-navigation li.active {
      background-color: #0eea8c; }
      #page-planos .section-planos .section-planos-content ul.planos-navigation li.active button {
        color: #1f194f; }
        #page-planos .section-planos .section-planos-content ul.planos-navigation li.active button :hover {
          color: #1f194f !important; }
  #page-planos .section-planos .section-planos-content .planos-navigation-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 150px; }
    #page-planos .section-planos .section-planos-content .planos-navigation-content .left {
      width: calc(65% - 15px);
      position: relative; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane {
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: opacity .4s,visibility .4s, height .4s; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane > div:not(:first-child) {
          padding: 25px 0; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane > div:not(:last-child) {
          border-bottom: 1px solid #2fc5f4; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .subtitle {
          font-size: 14px;
          font-weight: 900;
          color: #1f194f;
          padding-bottom: 20px; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .descricao {
          font-size: 18px;
          color: #808080;
          line-height: 25px;
          padding: 20px 0;
          border-bottom: 1px solid #2fc5f4; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane p:not(.descricao) {
          font-size: 18px;
          color: #808080;
          line-height: 25px;
          margin-bottom: 20px; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          border-bottom: 1px solid #2fc5f4; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .minima-select {
            position: relative; }
            #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .minima-select::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #000;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%); }
            #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .minima-select select {
              width: 100%;
              padding: 5px 15px;
              -webkit-appearance: none;
              -moz-appearance: none; }
              #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .minima-select select:focus {
                outline: none; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right {
            width: calc(50% - 15px); }
            #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .field-group, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .field-group {
              display: flex;
              flex-direction: column; }
              #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .field-group label, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .field-group label {
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: 900;
                color: #1f194f; }
            #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .custom-control, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .custom-control {
              height: 50px;
              display: flex;
              align-items: center; }
              #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .custom-control .control-radio-control:not(:last-child), #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .custom-control .control-radio-control:not(:last-child) {
                margin-right: 25px; }
              #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .custom-control .control-radio-control .custom-radio, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .custom-control .control-radio-control .custom-radio {
                margin-right: 10px; }
              #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-left .custom-control label, #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .col-right .custom-control label {
                font-size: 18px;
                color: #808080;
                font-weight: 400;
                margin-bottom: 0; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .faturamento-regime .form-control {
            position: relative;
            height: 50px;
            border-radius: 4px;
            border: 1px solid #2fc5f4;
            background-color: #fff;
            color: #808080; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .diferenciais ul {
          display: grid;
          grid-template-columns: [first] 50% [second] 50%;
          grid-column-gap: 20px;
          grid-row-gap: 20px; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .diferenciais ul li {
            display: flex;
            align-items: flex-start;
            color: #808080;
            font-size: 18px;
            line-height: 25px; }
            #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane .diferenciais ul li .icon {
              margin-right: 10px;
              margin-top: 3px; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane.active {
        opacity: 1;
        visibility: visible;
        height: 100%;
        padding-top: 25px;
        top: -25px; }
    #page-planos .section-planos .section-planos-content .planos-navigation-content .right {
      width: calc(35% - 15px); }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box {
        padding: 50px;
        background-color: #1f194f;
        border-radius: 8px;
        position: relative; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box header {
          font-size: 48px;
          font-weight: 900;
          color: #fff;
          text-align: center;
          margin-bottom: 40px; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box h6 {
          text-align: center;
          color: #0eea8c;
          font-size: 14px;
          margin-bottom: 10px; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          visibility: hidden;
          opacity: 0;
          transition: display .4s,visibility .4s, opacity .4s;
          width: 100%; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price.active {
            visibility: visible;
            opacity: 1;
            width: 100%; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price span {
            color: #fff;
            font-weight: 900; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price .moeda {
            font-size: 32px; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price .valor {
            font-size: 72px; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price .centavos {
            font-size: 32px;
            position: absolute; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box .price .perido {
            font-size: 14px;
            color: #0eea8c; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box button {
          margin: 100px auto 0; }
          @media (max-width: 595px) {
            #page-planos .section-planos .section-planos-content .planos-navigation-content .right .plano-box button {
              min-width: 100%; } }

@media (max-width: 425px) {
  #page-planos .section-hero-planos {
    height: 150px; }
    #page-planos .section-hero-planos img {
      height: 100%;
      object-fit: cover; }
  #page-planos .section-planos {
    margin: 60px 0; }
    #page-planos .section-planos .section-planos-content .title {
      margin-bottom: 30px !important; }
    #page-planos .section-planos .section-planos-content ul.planos-navigation {
      flex-wrap: wrap; }
      #page-planos .section-planos .section-planos-content ul.planos-navigation li {
        width: 50% !important; }
    #page-planos .section-planos .section-planos-content .left, #page-planos .section-planos .section-planos-content .right {
      width: 100% !important; }
    #page-planos .section-planos .section-planos-content .planos-navigation-content {
      flex-direction: column;
      padding-bottom: 0; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .planos-pane {
        position: relative !important; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .planos-pane .diferenciais ul {
          display: block !important; }
          #page-planos .section-planos .section-planos-content .planos-navigation-content .planos-pane .diferenciais ul li {
            margin-bottom: 25px; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .planos-pane.active {
        margin-bottom: 30px; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .faturamento-regime {
        flex-direction: column; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .faturamento-regime .col-left, #page-planos .section-planos .section-planos-content .planos-navigation-content .faturamento-regime .col-right {
          width: 100% !important; }
        #page-planos .section-planos .section-planos-content .planos-navigation-content .faturamento-regime .col-left {
          margin-bottom: 25px; } }

@media (max-width: 768px) {
  #page-planos .section-planos {
    margin: 60px 0; }
    #page-planos .section-planos .section-planos-content ul.planos-navigation {
      flex-wrap: wrap; }
      #page-planos .section-planos .section-planos-content ul.planos-navigation li {
        width: calc(100% / 3); }
    #page-planos .section-planos .section-planos-content .planos-navigation-content {
      flex-direction: column;
      padding-bottom: 0; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .left, #page-planos .section-planos .section-planos-content .planos-navigation-content .right {
        width: 100%; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane {
        position: relative; }
      #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane.active {
        margin-bottom: 30px; } }
      @media (max-width: 768px) and (max-width: 595px) {
        #page-planos .section-planos .section-planos-content .planos-navigation-content .left .planos-pane.active {
          top: 0; } }

#page-sobre > section:not(.section-footer) header {
  font-size: 72px;
  font-weight: 900;
  color: #0eea8c;
  margin-bottom: 35px; }

@media (max-width: 595px) {
  #page-sobre .section-hero-sobre {
    height: 150px; } }

#page-sobre .section-hero-sobre img {
  width: 100%; }
  @media (max-width: 595px) {
    #page-sobre .section-hero-sobre img {
      height: 100%;
      object-fit: cover; } }

#page-sobre .section-sobre {
  padding: 100px 0 50px; }
  #page-sobre .section-sobre .flex-wrapper {
    margin-bottom: 100px;
    width: 70%; }
    @media (max-width: 595px) {
      #page-sobre .section-sobre .flex-wrapper {
        margin-bottom: 50px;
        width: 100%; } }
    #page-sobre .section-sobre .flex-wrapper .description {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 595px) {
        #page-sobre .section-sobre .flex-wrapper .description {
          flex-direction: column; } }
      #page-sobre .section-sobre .flex-wrapper .description p {
        font-size: 18px;
        line-height: 25px;
        color: #808080; }
        @media (max-width: 595px) {
          #page-sobre .section-sobre .flex-wrapper .description p {
            font-size: 16px;
            line-height: 23px; } }
      #page-sobre .section-sobre .flex-wrapper .description .column {
        width: calc(50% - 15px); }
        @media (max-width: 595px) {
          #page-sobre .section-sobre .flex-wrapper .description .column {
            width: 100%; } }
  #page-sobre .section-sobre .infos-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px; }
    @media (max-width: 595px) {
      #page-sobre .section-sobre .infos-wrapper {
        flex-direction: column;
        margin-bottom: 0; } }
    #page-sobre .section-sobre .infos-wrapper .item {
      width: calc(100% / 3 - 15px);
      background-color: #2fc5f4;
      padding: 30px;
      border-radius: 8px;
      box-shadow: 0px 40px 60px 0px rgba(47, 197, 244, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      #page-sobre .section-sobre .infos-wrapper .item:not(:last-child) {
        margin-right: 15px; }
        @media (max-width: 595px) {
          #page-sobre .section-sobre .infos-wrapper .item:not(:last-child) {
            margin-right: 0; } }
      @media (max-width: 595px) {
        #page-sobre .section-sobre .infos-wrapper .item {
          width: 100%;
          margin-bottom: 15px; } }
      #page-sobre .section-sobre .infos-wrapper .item h2 {
        font-size: 64px;
        font-weight: 900;
        color: #fff;
        margin-bottom: 15px; }
      #page-sobre .section-sobre .infos-wrapper .item p {
        font-size: 24px;
        color: #1f194f;
        text-align: center; }

#page-sobre .section-depoimentos {
  position: relative;
  margin-bottom: 150px;
  z-index: 3; }
  #page-sobre .section-depoimentos header.title {
    text-align: center;
    margin-bottom: 75px; }

#page-sobre .section-depoimentos-content .depoimentos {
  width: 690px;
  max-width: 100%;
  margin: 75px auto 100px; }
  #page-sobre .section-depoimentos-content .depoimentos .item p {
    font-size: 24px;
    text-align: center;
    line-height: 26px;
    color: #1f194f;
    padding: 0 115px;
    padding-bottom: 10px; }
    @media (max-width: 595px) {
      #page-sobre .section-depoimentos-content .depoimentos .item p {
        font-size: 18px;
        line-height: 25px;
        padding: 0; } }

#page-sobre .section-depoimentos-content .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%); }
  @media (max-width: 595px) {
    #page-sobre .section-depoimentos-content .owl-nav {
      display: none; } }

#page-sobre .section-depoimentos-content .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: absolute;
  bottom: -60px;
  width: 100%; }
  #page-sobre .section-depoimentos-content .owl-dots .owl-dot {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: #a1a9bd;
    border-radius: 50%;
    transition: background-color .4s; }
    #page-sobre .section-depoimentos-content .owl-dots .owl-dot:not(last-child) {
      margin-left: 10px; }
    #page-sobre .section-depoimentos-content .owl-dots .owl-dot.active {
      background-color: #1f194f; }
    #page-sobre .section-depoimentos-content .owl-dots .owl-dot:hover {
      background-color: #0eea8c; }

#page-sobre .section-newsletter {
  position: relative;
  padding-bottom: 100px;
  z-index: 2; }
  #page-sobre .section-newsletter .background {
    width: 100%;
    position: absolute;
    bottom: 0; }
    #page-sobre .section-newsletter .background img {
      width: 100%; }
  #page-sobre .section-newsletter .newsletter {
    position: relative;
    z-index: 3; }
    #page-sobre .section-newsletter .newsletter .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 595px) {
        #page-sobre .section-newsletter .newsletter .flex-wrapper {
          flex-direction: column; } }
      #page-sobre .section-newsletter .newsletter .flex-wrapper .left {
        background-color: #0eea8c;
        box-shadow: 0px 40px 60px 0px rgba(14, 232, 140, 0.4);
        border-radius: 8px 0 0 8px; }
        @media (max-width: 595px) {
          #page-sobre .section-newsletter .newsletter .flex-wrapper .left {
            border-radius: 8px 8px 0 0;
            box-shadow: none; } }
        #page-sobre .section-newsletter .newsletter .flex-wrapper .left h3 {
          font-size: 32px;
          color: #fff; }
          @media (max-width: 595px) {
            #page-sobre .section-newsletter .newsletter .flex-wrapper .left h3 {
              font-size: 24px; } }
      #page-sobre .section-newsletter .newsletter .flex-wrapper .right {
        background-color: #fff;
        box-shadow: 0px 40px 60px 0px rgba(192, 214, 222, 0.45);
        border-radius: 0 8px 8px 0; }
        @media (max-width: 595px) {
          #page-sobre .section-newsletter .newsletter .flex-wrapper .right {
            border-radius: 0 0 8px 8px; } }
        #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form {
          width: 100%;
          position: relative; }
          #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #0eea8c;
            transition: border-color 0.5s cubic-bezier(0, 0, 0.3, 1); }
            #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group .field {
              color: #808080;
              font-size: 14px;
              font-weight: normal;
              position: relative;
              background-color: transparent;
              border: none;
              width: 100%;
              padding: 0 0 5px;
              z-index: 1; }
            #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group .placeholder {
              color: #808080;
              font-size: 14px;
              font-weight: normal;
              position: absolute;
              top: 2px;
              left: 0;
              z-index: 2;
              transition: opacity 0.5s cubic-bezier(0, 0, 0.3, 1); }
            #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group.invalid {
              border-color: red; }
            #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group.not-empty, #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group.focus {
              border-color: #2fc5f4; }
              #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group.not-empty .placeholder, #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form .field-group.focus .placeholder {
                opacity: 0; }
        #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form-submit {
          position: absolute;
          top: -20px;
          right: 0; }
          #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form-submit button {
            transition: transform .4s; }
            #page-sobre .section-newsletter .newsletter .flex-wrapper .right .form-submit button:hover img {
              transform: scale(1.2); }
      #page-sobre .section-newsletter .newsletter .flex-wrapper .left, #page-sobre .section-newsletter .newsletter .flex-wrapper .right {
        width: 50%;
        height: 205px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px; }
        @media (max-width: 595px) {
          #page-sobre .section-newsletter .newsletter .flex-wrapper .left, #page-sobre .section-newsletter .newsletter .flex-wrapper .right {
            width: 100%;
            height: 150px;
            padding: 30px; } }
        @media (min-width: 596px) and (max-width: 768px) {
          #page-sobre .section-newsletter .newsletter .flex-wrapper .left, #page-sobre .section-newsletter .newsletter .flex-wrapper .right {
            width: 100%; } }

#page-como-funciona > section {
  position: relative;
  z-index: 2; }

#page-como-funciona > section:not(.section-footer) header {
  font-size: 72px;
  font-weight: 900;
  color: #0eea8c;
  margin-bottom: 35px; }

@media (max-width: 595px) {
  #page-como-funciona .section-hero-como-funciona {
    height: 150px; } }

#page-como-funciona .section-hero-como-funciona img {
  width: 100%; }
  @media (max-width: 595px) {
    #page-como-funciona .section-hero-como-funciona img {
      height: 100%;
      object-fit: cover; } }

#page-como-funciona .section-como-funciona {
  padding: 100px 0 50px;
  z-index: 5; }
  #page-como-funciona .section-como-funciona .flex-wrapper {
    margin-bottom: 100px; }
    @media (max-width: 595px) {
      #page-como-funciona .section-como-funciona .flex-wrapper {
        margin-bottom: 50px; } }
    #page-como-funciona .section-como-funciona .flex-wrapper .description {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 595px) {
        #page-como-funciona .section-como-funciona .flex-wrapper .description {
          flex-direction: column; } }
      #page-como-funciona .section-como-funciona .flex-wrapper .description p {
        font-size: 18px;
        line-height: 25px;
        color: #808080; }
        @media (max-width: 595px) {
          #page-como-funciona .section-como-funciona .flex-wrapper .description p {
            font-size: 16px;
            line-height: 23px; } }
      @media (max-width: 595px) {
        #page-como-funciona .section-como-funciona .flex-wrapper .description .column {
          width: 100%; } }
      #page-como-funciona .section-como-funciona .flex-wrapper .description .column > *:not(:last-child) {
        margin-bottom: 15px; }
  #page-como-funciona .section-como-funciona .infos-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px; }
    @media (max-width: 595px) {
      #page-como-funciona .section-como-funciona .infos-wrapper {
        flex-direction: column;
        margin-bottom: 0; } }
    #page-como-funciona .section-como-funciona .infos-wrapper .item {
      width: calc(100% / 4 - 15px);
      border: 1px solid #0eea8c;
      background-color: transparent;
      padding: 30px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      #page-como-funciona .section-como-funciona .infos-wrapper .item:not(:last-child) {
        margin-right: 15px; }
        @media (max-width: 595px) {
          #page-como-funciona .section-como-funciona .infos-wrapper .item:not(:last-child) {
            margin-right: 0; } }
      @media (max-width: 595px) {
        #page-como-funciona .section-como-funciona .infos-wrapper .item {
          width: 100%;
          margin-bottom: 15px; } }
      #page-como-funciona .section-como-funciona .infos-wrapper .item h2 {
        font-size: 64px;
        font-weight: 900;
        color: #2fc5f4;
        margin-bottom: 15px; }
      #page-como-funciona .section-como-funciona .infos-wrapper .item p {
        font-size: 24px;
        color: #1f194f;
        text-align: center;
        line-height: 1.4; }

#page-como-funciona .section-empresas {
  margin-bottom: 100px; }
  @media (max-width: 595px) {
    #page-como-funciona .section-empresas {
      margin-bottom: 50px; } }
  #page-como-funciona .section-empresas .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -300px;
    left: 0;
    z-index: 1; }
    @media (max-width: 595px) {
      #page-como-funciona .section-empresas .background {
        top: 0; } }
    #page-como-funciona .section-empresas .background img {
      width: 100%; }
  #page-como-funciona .section-empresas .container {
    position: relative;
    z-index: 2; }
  #page-como-funciona .section-empresas header.title {
    font-size: 42px;
    margin-bottom: 75px; }
  #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper.full-width .left, #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper.full-width .right {
      width: 100%; }
    @media (max-width: 595px) {
      #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper {
        flex-direction: column; } }
    #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .left, #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .right {
      width: calc(50% - 30px); }
      @media (max-width: 595px) {
        #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .left, #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .right {
          width: 100%; } }
    #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .left .description {
      font-size: 18px;
      line-height: 25px;
      color: #808080; }
      @media (max-width: 595px) {
        #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .left .description {
          font-size: 16px; } }
    @media (max-width: 595px) {
      #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .left {
        margin-bottom: 25px; } }
    #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .right ul li {
      color: #1f194f;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 20px;
      position: relative; }
      @media (max-width: 595px) {
        #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .right ul li {
          font-size: 18px; } }
      #page-como-funciona .section-empresas .section-empresas-content .flex-wrapper .right ul li::before {
        content: '•';
        margin-right: 5px;
        color: #2fc5f4; }

#page-como-funciona .section-sistema {
  margin-bottom: 100px; }
  @media (max-width: 595px) {
    #page-como-funciona .section-sistema {
      margin-bottom: 50px; } }
  #page-como-funciona .section-sistema header.title {
    font-size: 48px;
    text-align: center;
    margin-bottom: 75px; }
  #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 595px) {
      #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper {
        flex-direction: column; } }
    #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .left, #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .right {
      width: calc(50% - 30px); }
      @media (max-width: 595px) {
        #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .left, #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .right {
          width: 100%; } }
    #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .left .featured-img {
      border-radius: 8px;
      box-shadow: 0px 40px 60px 0px rgba(133, 167, 179, 0.45);
      overflow: hidden; }
      #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .left .featured-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: -5px; }
    @media (max-width: 595px) {
      #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .left {
        margin-bottom: 25px; } }
    #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .right .description p {
      font-size: 18px;
      line-height: 25px;
      color: #808080; }
      @media (max-width: 595px) {
        #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .right .description p {
          font-size: 16px; } }
    #page-como-funciona .section-sistema .section-sistema-content .flex-wrapper .right .description p:not(:last-child) {
      margin-bottom: 25px; }

#page-como-funciona .section-servicos {
  margin-bottom: 100px; }
  @media (max-width: 595px) {
    #page-como-funciona .section-servicos {
      margin-bottom: 50px; } }
  #page-como-funciona .section-servicos header.title {
    font-size: 48px; }
  #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 595px) {
      #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper {
        flex-direction: column; } }
    #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .left, #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right {
      width: calc(50% - 30px); }
      @media (max-width: 595px) {
        #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .left, #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right {
          width: 100%; } }
    #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .left .description p {
      font-size: 18px;
      line-height: 25px;
      color: #808080; }
    #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .left .description p:not(:last-child) {
      margin-bottom: 25px; }
    @media (max-width: 595px) {
      #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .left {
        margin-bottom: 25px; } }
    #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right {
      margin-top: -82px; }
      @media (max-width: 595px) {
        #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right {
          margin-top: 0; } }
      #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right ul li {
        display: inline-block;
        padding: 30px;
        border-radius: 8px;
        background-color: #2fc5f4;
        color: #fff;
        width: 100%;
        font-weight: 700;
        font-size: 22px;
        position: relative;
        box-shadow: 0px 40px 60px 0px rgba(47, 197, 244, 0.45); }
        @media (max-width: 595px) {
          #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right ul li {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px; } }
        #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right ul li::before {
          content: '•';
          margin-right: 5px;
          color: #fff; }
      #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper .right ul li:not(:last-child) {
        margin-bottom: 20px; }
    #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper.full-width {
      flex-direction: column; }
      #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper.full-width .left, #page-como-funciona .section-servicos .section-servicos-content .flex-wrapper.full-width .right {
        width: 100%; }

#page-como-funciona .section-perguntas {
  padding-bottom: 100px; }
  @media (max-width: 595px) {
    #page-como-funciona .section-perguntas {
      display: none; } }
  #page-como-funciona .section-perguntas .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    overflow: hidden;
    bottom: 0; }
    @media (max-width: 595px) {
      #page-como-funciona .section-perguntas .background {
        top: 0; } }
    #page-como-funciona .section-perguntas .background img {
      position: relative;
      top: -100px;
      width: 100%;
      object-fit: cover;
      object-position: top; }
  #page-como-funciona .section-perguntas .container {
    position: relative;
    z-index: 2; }
  #page-como-funciona .section-perguntas header.title {
    font-size: 48px; }
  #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas {
    flex-direction: column; }
    #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li {
      width: 100%; }
      #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li button {
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li button .title {
          font-size: 22px;
          color: #1f194f;
          font-weight: 700; }
          #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li button .title span {
            margin-right: 15px;
            color: #2fc5f4; }
        #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li button .arrow {
          transition: transform .4s;
          padding-right: 15px; }
        #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li button .arrow.rotate {
          transform: rotate(-180deg); }
    #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas li:not(:last-child) {
      border-bottom: 1px solid #2fc5f4; }
    #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content {
      width: 100%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: height .4s, opacity .4s; }
      #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content ul li {
        font-size: 18px;
        color: #808080;
        border-bottom: 0;
        margin-top: 15px; }
        #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content ul li a {
          color: #1f194f;
          font-weight: 700;
          text-decoration: underline !important; }
          #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content ul li a:hover {
            color: #0eea8c; }
        #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content ul li::before {
          content: '•';
          margin-right: 5px;
          color: #808080; }
      #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content.active {
        height: 100%;
        padding: 30px;
        opacity: 1;
        visibility: visible; }
      #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content p {
        color: grey;
        font-size: 18px;
        margin: 15px 0;
        line-height: 2; }
      #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content b, #page-como-funciona .section-perguntas .section-perguntas-content ul.navigation-perguntas .content strong {
        font-weight: 700;
        color: #1f194f;
        font-weight: 18px; }

@media (max-width: 595px) {
  #page-contato .section-hero-contato {
    height: 150px; } }

#page-contato .section-hero-contato img {
  width: 100%; }
  @media (max-width: 595px) {
    #page-contato .section-hero-contato img {
      height: 100%;
      object-fit: cover; } }

#page-contato .section-contato {
  padding: 100px 0; }
  @media (max-width: 595px) {
    #page-contato .section-contato {
      padding: 50px 0; } }
  #page-contato .section-contato header.title {
    font-size: 72px;
    font-weight: 900;
    color: #0eea8c;
    margin-bottom: 25px; }
  #page-contato .section-contato h6 {
    font-size: 16px;
    color: #1f194f;
    font-weight: normal; }
    #page-contato .section-contato h6 strong {
      color: #2fc5f4; }
  #page-contato .section-contato .section-contato-content .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 595px) {
      #page-contato .section-contato .section-contato-content .flex-wrapper {
        flex-direction: column; } }
    #page-contato .section-contato .section-contato-content .flex-wrapper .left {
      width: calc(30% - 15px); }
      @media (max-width: 595px) {
        #page-contato .section-contato .section-contato-content .flex-wrapper .left {
          width: 100%; } }
    #page-contato .section-contato .section-contato-content .flex-wrapper .right {
      width: calc(70% - 15px); }
      @media (max-width: 595px) {
        #page-contato .section-contato .section-contato-content .flex-wrapper .right {
          width: 100%; } }
      #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
        @media (max-width: 595px) {
          #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .wrapper {
            flex-direction: column; } }
        #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .wrapper > div {
          width: calc(50% - 15px); }
          @media (max-width: 595px) {
            #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .wrapper > div {
              width: 100%; } }
        #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .wrapper .form-right .field-group:last-child {
          height: 208px; }
      #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .form-submit {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .form-submit button {
          text-transform: uppercase; }
          @media (max-width: 595px) {
            #page-contato .section-contato .section-contato-content .flex-wrapper .right .form .form-submit button {
              width: 100%; } }

#page-abertura .loading-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all .5s; }
  #page-abertura .loading-modal.show {
    opacity: 1;
    visibility: visible; }
  #page-abertura .loading-modal span {
    display: block;
    background: white;
    border-radius: 5px;
    padding: 25px 35px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }

@media (max-width: 595px) {
  #page-abertura .section-hero-abertura {
    height: 150px; } }

#page-abertura .section-hero-abertura img {
  width: 100%; }
  @media (max-width: 595px) {
    #page-abertura .section-hero-abertura img {
      height: 100%;
      object-fit: cover; } }

#page-abertura .section-abertura {
  padding: 100px 0; }
  @media (max-width: 595px) {
    #page-abertura .section-abertura {
      padding: 50px 0; } }
  #page-abertura .section-abertura .section-abertura-content header.title {
    font-size: 48px;
    font-weight: 900;
    color: #0eea8c;
    margin-bottom: 25px; }
  #page-abertura .section-abertura .section-abertura-content .description p {
    font-size: 18px;
    line-height: 25px;
    color: #808080;
    margin-bottom: 25px; }
    @media (max-width: 595px) {
      #page-abertura .section-abertura .section-abertura-content .description p {
        font-size: 16px;
        line-height: 23px; } }

#page-abertura .formulario .wrapper {
  width: 790px;
  max-width: 100%;
  margin: 0 auto; }
  #page-abertura .formulario .wrapper .titulo-sessao {
    color: #1f194f;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 35px; }
  #page-abertura .formulario .wrapper .subtitulo-sessao {
    color: #0eea8c;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 35px; }
  #page-abertura .formulario .wrapper .row-radios {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    padding-bottom: 7px; }
    #page-abertura .formulario .wrapper .row-radios .row-title {
      color: #808080;
      margin-right: 15px;
      font-weight: 700; }
    #page-abertura .formulario .wrapper .row-radios .field:not(:last-child) {
      margin-right: 15px; }
  #page-abertura .formulario .wrapper .row-radio-wrapper {
    display: flex;
    align-items: center; }
    #page-abertura .formulario .wrapper .row-radio-wrapper .radio-wrapper:not(:last-child) {
      margin-right: 35px; }
  #page-abertura .formulario .wrapper .div-socio {
    margin-bottom: 30px; }
  #page-abertura .formulario .wrapper .btn-wrapper {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #1f194f; }
  #page-abertura .formulario .wrapper .endereco-empresa-wrapper .fields-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    #page-abertura .formulario .wrapper .endereco-empresa-wrapper .fields-wrapper .field-group {
      width: calc(100% / 4 - 15px); }
  #page-abertura .formulario .wrapper .field-group[data-name="capital-valor-quota"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="capital-valor-quota"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="capital-valor-bens"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="capital-valor-bens"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio1"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio1"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio1"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio1"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio2"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio2"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio2"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio2"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio3"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio3"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio3"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio3"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio4"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-quota-socio4"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio4"] {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: display .4s ease-in-out, visibility .4s ease-in-out, opacity .4s ease-in-out; }
    #page-abertura .formulario .wrapper .field-group[data-name="valor-bens-socio4"].show {
      display: block;
      visibility: visible;
      opacity: 1; }
  #page-abertura .formulario .wrapper .documentos-necessarios-wrapper ul {
    margin-bottom: 35px; }
    #page-abertura .formulario .wrapper .documentos-necessarios-wrapper ul li {
      color: #808080;
      font-size: 14px; }
      #page-abertura .formulario .wrapper .documentos-necessarios-wrapper ul li:not(:last-child) {
        margin-bottom: 15px; }

#page-abertura .btn-add-socio {
  transition: background-color .4s ease-in-out, color .4s ease-in-out; }
  #page-abertura .btn-add-socio.disabled {
    pointer-events: none;
    background-color: #808080;
    color: #bfbbbb; }

#page-abertura .formulario .form-submit {
  width: 790px;
  max-width: 100%;
  margin: 0 auto; }

#page-contratacao .progress {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s; }
  #page-contratacao .progress.active {
    opacity: 1;
    visibility: visible; }
  #page-contratacao .progress-wrapper {
    color: #1f194f;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: white;
    padding: 32px 60px 29px;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: background-color .3s, padding .3s; }
    #page-contratacao .progress-wrapper span {
      line-height: 1.4;
      transition: color .3s; }
    #page-contratacao .progress-wrapper button {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #1f194f;
      background-color: #0eea8c;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      padding: 8px 20px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      transition: background-color .3s, color .3s, opacity .3s, visibility .3s; }
      #page-contratacao .progress-wrapper button:hover {
        color: white;
        background-color: #1f194f; }
  #page-contratacao .progress.success .progress-wrapper {
    background-color: #0eea8c; }
  #page-contratacao .progress.error .progress-wrapper {
    padding-bottom: 36px; }
  #page-contratacao .progress.error span {
    color: #ff284b; }
  #page-contratacao .progress:not(.error) button {
    opacity: 0;
    visibility: hidden; }

@media (max-width: 595px) {
  #page-contratacao .section-hero-contratacao {
    height: 150px; } }

#page-contratacao .section-hero-contratacao img {
  width: 100%; }
  @media (max-width: 595px) {
    #page-contratacao .section-hero-contratacao img {
      height: 100%;
      object-fit: cover; } }

#page-contratacao .section-contratacao {
  padding: 100px 0; }
  @media (max-width: 595px) {
    #page-contratacao .section-contratacao {
      padding: 50px 0; } }
  #page-contratacao .section-contratacao .section-contratacao-content header.title {
    font-size: 72px;
    font-weight: 900;
    color: #0eea8c;
    margin-bottom: 75px; }
    @media (max-width: 595px) {
      #page-contratacao .section-contratacao .section-contratacao-content header.title {
        margin-bottom: 35px; } }
  #page-contratacao .section-contratacao .section-contratacao-content .description p {
    font-size: 18px;
    line-height: 25px;
    color: #808080;
    margin-bottom: 25px; }
    @media (max-width: 595px) {
      #page-contratacao .section-contratacao .section-contratacao-content .description p {
        font-size: 16px;
        line-height: 23px; } }
  #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 768px) {
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper {
        flex-direction: column-reverse; } }
    #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left {
      width: calc(65% - 15px); }
      @media (max-width: 768px) {
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left {
          width: 100%; } }
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .subtitle {
        font-size: 18px;
        color: #1f194f;
        font-weight: 900;
        margin-bottom: 30px; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .subtitle span {
          color: #2fc5f4;
          margin-right: 5px; }
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 2px solid #0eea8c; }
        @media (max-width: 768px) {
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper {
            flex-direction: column; } }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper > div {
          width: calc(50% - 15px); }
          @media (max-width: 768px) {
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper > div {
              width: 100%; } }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper .fields-endereco {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper .fields-endereco .field-group {
            width: calc(50% - 15px); }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper .fields-cartao {
          margin-top: -33px;
          margin-bottom: 35px; }
          @media (max-width: 768px) {
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .wrapper .fields-cartao {
              margin-top: 0; } }
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .cartoes {
        margin-bottom: 50px; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .cartoes span {
          font-size: 18px;
          color: #1f194f;
          margin-bottom: 15px;
          display: block; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .cartoes img {
          margin-bottom: 10px; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .cartoes img:not(:last-child) {
            margin-right: 10px; }
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .form-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        @media (max-width: 768px) {
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .form-btn-wrapper .form-submit {
            width: 100%; } }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .form-btn-wrapper .form-submit button {
          text-transform: uppercase; }
          @media (max-width: 595px) {
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .left .item .form .form-btn-wrapper .form-submit button {
              width: 100%; } }
    #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right {
      width: calc(35% - 15px); }
      @media (max-width: 768px) {
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right {
          width: 100%; } }
      #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box {
        background-color: #1f194f;
        border-radius: 8px;
        padding: 30px;
        text-align: center;
        box-shadow: 0px 40px 80px 0px rgba(31, 25, 79, 0.5); }
        @media (max-width: 768px) {
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box {
            width: 100%; } }
        @media (max-width: 768px) {
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box {
            margin-bottom: 35px; } }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box header {
          font-size: 48px;
          font-weight: 900;
          color: #fff;
          text-align: center;
          margin-bottom: 25px; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .box-subtitle {
          width: 80%;
          font-size: 18px;
          font-weight: 900;
          color: #0eea8c;
          text-transform: uppercase;
          text-align: center;
          padding-bottom: 15px;
          border-bottom: 1px solid #0eea8c;
          display: inline-block; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .faturamento-content, #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .regime-content {
          width: 80%;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 15px auto;
          padding: 15px 0;
          border-bottom: 1px solid #0eea8c;
          display: inline-block; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .faturamento-content h4, #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .regime-content h4 {
            color: #2fc5f4;
            margin-bottom: 5px; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .faturamento-content p, #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .regime-content p {
            color: #fff; }
        #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price {
          margin-top: 25px; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price h6 {
            text-align: center;
            color: #0eea8c;
            font-size: 14px;
            margin-bottom: 10px; }
          #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price {
            text-align: center; }
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price span {
              color: #fff;
              font-weight: 900; }
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price .moeda {
              font-size: 32px; }
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price .valor {
              font-size: 63px; }
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price .centavos {
              font-size: 32px;
              position: absolute; }
            #page-contratacao .section-contratacao .section-contratacao-content .flex-wrapper .right .plano-box .full-price .price .perido {
              font-size: 14px;
              color: #0eea8c; }

/** @import: Pages [Mobile]. */
