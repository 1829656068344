#page-planos {
  .plans {
    &--navigation {
      border-bottom: 1px solid color(picton-blue);
      display: flex;

      @media (max-width: 568px) {
        border: none;
      }

      .item {
        width: 16.67%;

        button {
          color: fade-out(black, .8);
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 80px;
          padding: 4px 20px 0;
          transition: color .15s, background-color .15s;

          &:hover {
            color: color(valhalla);
            transition: none;
          }
        }

        &.active button {
          color: color(valhalla);
          background-color: color(spring-green);
        }
      }

      .mobile-select {
        width: 100%;
        position: relative;

        select {
          appearance: none;
          background-color: white;
          border: 1px solid color(picton-blue);
          display: block;
          width: 100%;
          height: 50px;
          padding: 2px 14px 0;
        }

        &::after {
          content: '';
          border-top: 6px solid color(valhalla);
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          position: absolute;
          right: 15px;
          top: 50%;
          margin-top: -2px;
        }
      }
    }

    &--content {
      display: flex;
      align-items: flex-start;

      @media (max-width: 768px) {
        display: block;
      }

      .tabs {
        width: 100%;
        position: relative;

        .tab {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          padding-right: 40px;
          opacity: 0;
          visibility: hidden;
          transition: opacity .25s, visibility .25s;

          @media (max-width: 768px) {
            padding: 0;
          }

          &.active {
            opacity: 1;
            visibility: visible;
          }

          .row {
            padding: 30px 0;

            &:not(:last-child) {
              border-bottom: 1px solid color(picton-blue);
            }

            &--title {
              color: color(valhalla);
              font-size: 14px;
              text-transform: uppercase;
              font-weight: bold;
              display: block;
              margin-bottom: 15px;
            }

            &-plan_description {
              .row--content p {
                line-height: 1.4;

                &:not(:last-child) {
                  margin-bottom: 12px;
                }
              }
            }

            &-plan_settings {
              .row--content {
                display: flex;

                @media (max-width: 768px) {
                  display: block;
                }
              }

              .option {
                width: calc(33.33% - 20px);

                &:not(:last-child) {
                  margin-right: 30px;
                }

                @media (max-width: 768px) {
                  width: 100%;
                  margin: 0;

                  &:not(:last-child) {
                    margin-bottom: 45px;
                  }
                }

                .label {
                  color: color(valhalla);
                  font-size: 14px;
                  font-weight: bold;
                  line-height: 1.4;
                  text-transform: uppercase;
                  height: 38px;
                  margin-bottom: 15px;

                  @media (max-width: 768px) {
                    height: auto;
                  }
                }

                .field {
                  &-select {
                    position: relative;

                    select {
                      appearance: none;
                      background-color: white;
                      border: 1px solid color(picton-blue);
                      display: block;
                      width: 100%;
                      height: 50px;
                      padding: 2px 14px 0;
                    }

                    &::after {
                      content: '';
                      border-top: 6px solid color(valhalla);
                      border-right: 5px solid transparent;
                      border-left: 5px solid transparent;
                      position: absolute;
                      right: 15px;
                      top: 50%;
                      margin-top: -2px;
                      pointer-events: none;
                    }
                  } 

                  &-radio {
                    height: 40px;
                    padding-top: 4px;

                    .radio {
                      display: flex;
                      align-items: center;

                      &:not(:last-child) {
                        margin-bottom: 10px;
                      }

                      input {
                        display: none;
                      }

                      label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &::before {
                          width: 14px;
                          height: 14px;
                        }

                        &::after {
                          top: 5px;
                          left: 5px;
                        }

                        .text {
                          position: relative;
                          top: 1px;
                        }
                      }
                    }
                  }
                }
              }
            }

            &-plan_features {
              ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 30px;

                @media (max-width: 768px) {
                  display: block;
                }

                li {
                  display: flex;
                  align-items: flex-start;

                  &:not(:last-child) {
                    margin-bottom: 12px;
                  }

                  span {
                    display: block;
                  }

                  .icon {
                    position: relative;
                    top: 1px;
                    margin-right: 10px;
                  }

                  .text {
                    line-height: 1.4;
                  }
                }
              }
            }
          }
        }
      }

      .price-box {
        color: white;
        background-color: color(valhalla);
        border-radius: 10px;
        width: 370px;
        flex-shrink: 0;
        margin-top: 30px;
        padding: 70px 50px;

        @media (max-width: 768px) {
          width: 100%;
          padding: 40px 20px;
        }

        .headline {
          font-weight: bold;
          font-size: 42px;
          text-align: center;
          display: block;
          margin-bottom: 30px;

          @media (max-width: 320px) {
            font-size: 32px;
          }
        }

        .price-display {
          position: relative;

          .from {
            color: color(spring-green);
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
          }

          .price {
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .loading-icon {
              display: block;
              width: 31px;
              position: absolute;
              top: 50%;
              left: 50%;
              opacity: 0;
              transform: translate(-50%, -50%);
              pointer-events: none;
              transition: opacity .25s;

              &::before {
                content: '';
                display: block;
                padding-top: 100%;
              }

              svg {
                animation: vig_loader__rotate 1.8s linear infinite;
                height: 100%;
                transform-origin: center center;
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;

                /** Animation keyframes. */
                @keyframes vig_loader__rotate {
                  100% {
                    transform: rotate(360deg);
                  }
                }

                circle {
                  fill: none;
                  stroke: white;
                  stroke-width: 3px;
                  stroke-dasharray: 1, 200;
                  stroke-dashoffset: 0;
                  animation: vig_loader__dash 1.5s ease-in-out infinite;
                  stroke-linecap: round;

                  /** Animation keyframes. */
                  @keyframes vig_loader__dash {
                    0% {
                      stroke-dasharray: 1, 200;
                      stroke-dashoffset: 0;
                    }
                    50% {
                      stroke-dasharray: 89, 200;
                      stroke-dashoffset: -35px;
                    }
                    100% {
                      stroke-dasharray: 89, 200;
                      stroke-dashoffset: -124px;
                    }
                  }
                }
              }
            }

            span {
              transition: opacity .25s;
            }

            &.loading {
              .loading-icon {
                opacity: 1;
              }

              span {
                opacity: .1;
              }
            }

            &-prefix,
            &-decimals {
              font-size: 32px;
              position: relative;

              @media (max-width: 320px) {
                font-size: 24px;
              }
            }

            &-prefix {
              top: 10px;
              margin-right: 4px;
            }

            &-main {
              font-size: 62px;
              font-weight: 900;

              @media (max-width: 320px) {
                font-size: 50px;
              }
            }

            &-decimals {
              top: -10px;

              &::before {
                content: ',';
              }
            }
          }

          .payment-info {
            color: color(spring-green);
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            display: block;
            margin-top: 6px;
          }
        }

        .select-plan-action {
          text-align: center;
          margin-top: 30px;

          .select-plan {
            color: color(valhalla);
            background-color: color(spring-green);
            border-radius: 30px;
            font-size: 14px;
            font-weight: bold;
            line-height: 60px;
            text-align: center;
            text-transform: uppercase;
            display: inline-block;
            height: 63px;
            padding: 0 42px;
            transition: background-color .25s;

            @media (max-width: 320px) {
              height: 50px;
              border-radius: 25px;
              line-height: 52px;
            }

            &:hover {
              background-color: white;
            }
          }
        }
      }
    }
  }
}

#page-planos{
  .section-hero-planos{

    img{
        width: 100%;
      }
    }
    .section-planos{
      margin:60px 0 150px;

      .section-planos-content{

        header.title{
          font-size: 72px;
          color: color(spring-green);
          font-weight: 900;
          margin-bottom: 70px;
        }

        ul.planos-navigation{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          border-bottom: 1px solid color(picton-blue);
          margin-bottom: 25px;

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 6);
            text-align: center;
            line-height: 21px;

            button{
              width: 100%;
              height: 90px;
              color: color(pale-grey);
              font-weight: 900;
              pointer-events: cursor;
              text-transform: uppercase;
              padding: 0 15px;
            }
          }
          li.active{
            background-color: color(spring-green);

            button{
              color: color(valhalla);

              :hover{
                color:color(valhalla)!important;
              }
            }
          }
        }
        .planos-navigation-content{

          display:flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 150px;

          .left{
            width: calc(65% - 15px);
            position: relative;

            .planos-pane{
              position: absolute;
              top:0;
              left: 0;
              height: 0;
              visibility: hidden;
              opacity: 0;
              transition: opacity .4s,visibility .4s, height .4s;
;

              > div:not(:first-child){
                padding: 25px 0;

              }
              > div:not(:last-child){
                border-bottom: 1px solid color(picton-blue);
              }

              .subtitle{
                font-size: 14px;
                font-weight: 900;
                color: color(valhalla);
                padding-bottom: 20px;
              }
              .descricao{
                font-size: 18px;
                color: #808080;
                line-height: 25px;
                padding: 20px 0;
                border-bottom: 1px solid color(picton-blue);
              }
              p:not(.descricao){
                font-size: 18px;
                color: #808080;
                line-height: 25px;
                margin-bottom: 20px;
              }
              .faturamento-regime{
                // padding: 25px 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                border-bottom: 1px solid color(picton-blue);

                .minima-select{
                  position: relative;

                  &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;

                    border-top: 6px solid #000;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                  }

                  select{
                    width: 100%;
                    padding: 5px 15px;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    &:focus{
                      outline:none;
                    }
                  }
                }

                .col-left, .col-right{
                  width: calc(50% - 15px);

                  .field-group{
                    display: flex;
                    flex-direction: column;

                    label{
                      margin-bottom: 15px;
                      font-size: 14px;
                      font-weight: 900;
                      color: color(valhalla);
                    }
                  }
                  .custom-control{
                    height: 50px;
                    display: flex;
                    align-items: center;

                    .control-radio-control:not(:last-child){
                      margin-right: 25px;
                    }
                    .control-radio-control{

                      .custom-radio{
                        margin-right: 10px;
                      }
                    }
                    label{
                      font-size: 18px;
                      color: #808080;
                      font-weight: 400;
                      margin-bottom: 0;
                    }
                  }
                }
                .form-control{
                  position: relative;
                  height: 50px;
                  border-radius: 4px;
                  border: 1px solid color(picton-blue);
                  background-color: #fff;
                  color: #808080;
                }
              }
              .diferenciais{

                ul{
                  display: grid;
                  grid-template-columns: [first] 50% [second] 50%;
                  grid-column-gap: 20px;
                  grid-row-gap: 20px;

                  li{
                    display: flex;
                    align-items: flex-start;
                    color: #808080;
                    font-size: 18px;
                    line-height: 25px;

                    .icon{
                      margin-right: 10px;
                      margin-top: 3px;

                    }
                  }
                }

              }
            }
            .planos-pane.active{
              opacity: 1;
              visibility: visible;
              height: 100%;
              padding-top: 25px;
              top: -25px;
            }
          }
          .right{
            width: calc(35% - 15px);

            .plano-box{
              padding: 50px;
              background-color: color(valhalla);
              border-radius: 8px;
              position: relative;

              header{
                font-size: 48px;
                font-weight: 900;
                color: #fff;
                text-align: center;
                margin-bottom: 40px;
              }
              h6{
                text-align: center;
                color: color(spring-green);
                font-size: 14px;
                margin-bottom: 10px;
              }
              .price{
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                // display: none;
                visibility: hidden;
                opacity: 0;
                transition: display .4s,visibility .4s, opacity .4s;
                width: 100%;

                &.active{
                  visibility: visible;
                  opacity: 1;
                  // display: block;
                  width: 100%;

                }

                span{
                  color: #fff;
                  font-weight: 900;
                }

                .moeda{
                  font-size: 32px;
                }
                .valor{
                  font-size: 72px;
                }
                .centavos{
                  font-size: 32px;
                  position: absolute;
                }
                .perido{
                  font-size: 14px;
                  color: color(spring-green);
                }
              }
              button{
                margin: 100px auto 0;

                @media(max-width: 595px){
                  min-width: 100%;
                }
              }
            }
          }
        }
      }
    }

}
@media (max-width:425px){
  #page-planos{

    .section-hero-planos{
      height: 150px;

      img{
        height: 100%;
        object-fit: cover;
      }
    }
    .section-planos{
      margin: 60px 0;

      .section-planos-content{

        .title{
          margin-bottom: 30px!important;
        }
      }

      .section-planos-content{

        ul.planos-navigation{
          flex-wrap: wrap;

          li{
            width: 50%!important;
          }
        }
        .left, .right{
          width: 100%!important;
        }
        .planos-navigation-content{
          flex-direction: column;
          padding-bottom: 0;

          .planos-pane{
            position: relative!important;

            .diferenciais{

              ul{
                display: block!important;

                li{
                  margin-bottom: 25px;
                }

              }
            }
          }
          .planos-pane.active{
            margin-bottom: 30px;
          }

          .faturamento-regime{
            flex-direction: column;

            .col-left, .col-right{
              width: 100%!important;
            }
            .col-left{
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
@media (max-width:768px){
  #page-planos{

    .section-planos{
      margin: 60px 0;

      .section-planos-content{

        ul.planos-navigation{
          flex-wrap: wrap;

          li{
            width: calc(100% / 3);
          }
        }
        .planos-navigation-content{
          flex-direction: column;
          padding-bottom: 0;

          .left, .right{
            width: 100%
          }
          .left{

            .planos-pane{
              position: relative;
            }
            .planos-pane.active{
              margin-bottom: 30px;

              @media(max-width: 595px){
                top:0;
              }
            }
          }
        }
      }
    }
  }
}
