#page-home {
  a:not(.btn):hover {
  	color: color(spring-green)!important;
  }
}

.btn-area-cliente {
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
}

.btn-area-cliente::after {
  content: '';
  position: absolute;
  height: 50px;
  width: calc(100% + 10px);
  border: 2px solid #fff;
  border-radius: 35px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s, opacity 0.3s;
}

.btn-area-cliente:hover::after {
  opacity: .5;
  transform: scale(1);

}

.section-hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 60px;
  margin-bottom: 150px;

  &::before,
  &::after {
    content: '';
    width: 2px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    pointer-events: none;
    clip-path: polygon(0 -150%, 100% -150%, 100% 0, 0 0);
    margin-bottom: -3px
  }

  &::before {
    background-color: white;
    animation: home_hero_scroll_line 2s ease(inout) infinite;
    animation-delay: .3s;
    z-index: 5;
  }

  &::after {
    background-color: color(spring-green);
    animation: home_hero_scroll_line 2s ease(inout) infinite;
    z-index: 4;

    /** Animation keyframes. */
    @keyframes home_hero_scroll_line {
      0% {
        clip-path: polygon(0 -150%, 100% -150%, 100% 0, 0 0);
      }

      80%,
      100% {
        clip-path: polygon(0 100%, 100% 100%, 100% 350%, 0 350%);
      }
    }
  }

  .img-hero {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
    pointer-events: none;
    user-select: none;
  }

  .scrolldown {
    position: absolute;
    bottom: 75px;
    left: calc(50% - 10px);
  }

  .section-hero-container {
    position: relative;

    header h1 {
      font-size: 64px;
      color: #fff;
      font-weight: 900;
      width: 465px;
      max-width: 100%;
      transition: 200ms ease-in-out;
      opacity: 0;
    }
      span {
        .w:last-child {
          font-size: 96px;
          margin-top: 25px;
          position: relative;
          left: -5px;

          @media(max-width: 595px){
            font-size: 72px;
          }
        }
      }

    .bnt-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 60px;

      .btn:not(:last-child){
        margin-right: 30px;
      }
    }
  }
}

.section-middle {
  position: relative;

  @media (max-width: 568px) {
    margin-top: 60px;
  }

  .shape {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > section {
    position: relative;
    z-index: 2;
  }
}

.section-contabilidade {

  .container {
    width: 1170px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .section-contabilidade-container {
    padding-bottom: 160px;

    header {
      font-size: 56px;
      color: color(spring-green);
      font-weight: 900;
      width: 470px;
      max-width: 100%;
      margin-bottom: 65px;

      @media (max-width: 568px) {
        font-size: 38px !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-bottom: 40px;
      }
    }

    .contabilidade-content {
      display: flex;

      .left {
        width: calc(50% + 100px);
        padding: 115px 100px;
        padding-right: 200px;
        font-size: 18px;
        background-color: color(breeze);
        position: relative;
        z-index: 1;

        p {
          line-height: 25px;
          color: color(coal);

        }
        p:not(:last-child){
          margin-bottom: 25px;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        .featured-image {
          margin-top: -100px;
          width: 600px;
          height: 490px;

          img {
            box-shadow: 0 15px 30px rgba(0,0,0,.27);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.section-vantagens {
  padding-bottom: 160px;

  header {
    font-size: 56px;
    color: color(spring-green);
    font-weight: 900;
    max-width: 100%;
    margin-bottom: 90px;
    text-align: center;

    @media (max-width: 568px) {
      font-size: 38px;
      text-align: left;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    span {
      display: flex;
      flex-direction: column;
    }
  }

  .vantagens-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% / 3);

      img {
        width: 75px;
        margin-bottom: 25px;
      }

      p {
        font-size: 24px;
        font-weight: 700;
        color: color(valhalla);
        padding: 0 48px;
        line-height: 1.4;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .item:first-child {

      p {
        padding: 0 30px;
      }
    }
  }
}

.section-contrate {
  padding: 160px 0;

  .section-contrate-container .container {
    background-color: color(spring-green);
    height: 585px;
    display: flex;
    align-items: flex-end;

    .contrate-content {
      display: flex;
      position: relative;
    }

      .left {
        width: 50%;
        z-index: 2;

        img {
          margin-bottom: -3px;
        }
      }
      .right {
        width: calc(50% + 50px);
        position: absolute;
        right: 0;
        top: 90px;
        z-index: 1;

        .box {
          background-color: color(valhalla);
          color: #fff;
          padding: 130px 60px;

          header {
            font-size: 36px;
            font-weight: 900;
            margin-bottom: 35px;
            text-align: center;
          }

          .price {
            padding: 30px;
            border: 1px solid color(picton-blue);
            border-radius: 15px;
            position: relative;
            text-align: center;
            width: 70%;
            margin: 0 auto 35px;
          }
          .price span {
            font-weight: 900;
          }
          .price {
            .moeda {
              font-size: 32px;
            }
            .valor {
              font-size: 63px;
            }
            .centavos {
              font-size: 32px;
              position: absolute;
            }
            .perido {
              font-size: 14px;
              color: color(spring-green);
              display: block;
            }
          }

          .call-to-action {
            text-align: center;

            .btn-conheca-planos {
              line-height: 60px;
              display: inline-block;
              width: auto;
              min-width: none;
              padding: 0 30px;

              &::after {
                display: none;
              }

              &:hover {
                background-color: white;
              }
            }
          }
        }
      }
  }
}

.section-duvidas {
  background: url(../assets/images/home/duvidas.png)no-repeat center top;
  background-size: cover;
  padding-bottom: 100px;

  .section-duvidas-container {

    header {
      font-size: 56px;
      color: color(spring-green);
      font-weight: 900;
      max-width: 100%;
      margin-bottom: 35px;
    }

    p.subtitle {
      font-size: 18px;
      line-height: 25px;
      color: color(valhalla);
      width: 430px;
      max-width: 100%;
      margin-bottom: 50px;

      a {
        font-weight: 700;
        text-decoration: underline!important;
        color: color(valhalla);
      }
    }
    .duvidas-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .right {
        width: calc(45% - 15px);
      }
      .left {
        margin-right: 30px;
        width: calc(55% - 15px);
      }

      .duvidas-navigation {

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          border-radius: 4px;
          padding: 0 30px;

          &:hover {
            cursor: pointer;
          }

          button {
            font-weight: 700;
            color: color(valhalla);
            font-size: 18px;
            display: flex;
            text-align: left;

            span {
              margin-right: 10px;
            }
          }

        }
        li.active {
          background-color: color(picton-blue);
          transition: background-color 0.4s;

          span {
            color: #fff;
          }
        }
        li.active:hover {
          background-color: color(spring-green);
        }
      }
      .duvidas-navigation-content {
        padding: 30px;
        border: 1px solid color(picton-blue);
        border-radius: 16px;
        position: relative;
        height: 485px;


        .duvidas-pane {
          position: absolute;
          top:0;
          left: 0;
          width: 100%;
          visibility: hidden;
          opacity: 0;
          transition: opacity .4s,visibility .4s;
          padding: 30px;

          p,li,ol {
            font-size: 18px;
            line-height: 25px;
            color: color(coal);

          }
          p:not(:last-child), li:not(:last-child), ol:not(:last-child){
            margin-bottom: 15px;
          }
          b,strong {
            color: color(valhalla);
          }

          a {
            color: color(valhalla);
            font-weight: 700;
            text-decoration: underline!important;
            line-height: 26px;
          }
        }

        .duvidas-pane.active {
          visibility: visible;
          opacity: 1;

        }

        ul {
          list-style-type: initial;
          position: relative;

          li {
            margin-bottom: 20px;
          }
        }
        .atendimento {

          .item {

            h6{
              font-size: 18px;
              color: color(valhalla);
              font-weight: 700;
              margin-bottom: 5px
            }
            a {
              color: color(coal);
              font-weight: 700;
              text-decoration: underline!important;
            }
          }
          .item:not(:last-child){
            margin-bottom: 25px;
          }
        }
      }
    }
  }

}

// Media Queries
@media (max-width:1024px) {
  .section-middle {
    overflow: hidden;
  }
}

@media (min-width:426px) and (max-width:768px) {
  .container-medium {
    padding: 0 30px;
  }

  .section-contabilidade {
    .section-contabilidade-container {
      padding-bottom: 0;

      .contabilidade-content {
        flex-direction: column;

        .left,.right {
          width: 100%;
        }

        .left {
          padding: 50px;
        }

        .right {
          position: relative;
          transform: initial;

          .featured-image {
            margin-top: 0;
            width: 100%;
            height: auto;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .section-vantagens {
    padding-bottom: 0;

    .vantagens-content {
      flex-direction: column;

      .item {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .section-contrate {
    padding-bottom: 0;

    .section-contrate-container {
      padding-bottom: 60px;

      .container {

        .contrate-content {
          flex-direction: column;

          .left {
            width: 100%;
            .featured-image {
              display: none;
            }
          }
          .right {
            position: relative;
            width: 100%;
            top: -130px;
          }
        }
      }
    }
  }
}

@media (min-width:426px) and (max-width:650px){
  .section-duvidas .section-duvidas-container .duvidas-content {
    flex-direction: column;

    .left, .right {
      width: 100%;
    }

    .left ul {
      margin-bottom: 35px
    }
  }
}
@media (max-width:425px){

  section.section header {
    font-size: 32px!important;
  }
  .section-hero {
    margin-bottom: 0;
    padding-bottom: 100px;

    .scrolldown {
      display: none;
    }
  }
  .section-hero::after, .section-hero::before {
    display: none;
  }
  .section-hero .img-hero {
    height: 100vh;
  }
  .section-hero .section-hero-container header h1{
    font-size: 45px;
  }
  .section-hero .section-hero-container header h1 span b {
    font-size: 70px;
  }
  .section-hero .section-hero-container .scrolldown {
    display: none;
  }
	.bnt-wrapper {
		flex-direction: column;

		button {
			margin: 0;
			margin-bottom: 15px;
			width: 100%;
		}
    button:not(:last-child){
      margin-right: 0!important;
    }
	}
  .section-middle {
    overflow: hidden;
  }
  .section-contabilidade .section-contabilidade-container {
    padding-bottom: 60px;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .left {
    width: 100%;
    padding: 30px;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
    width: 100%;
    position: relative;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image {
    margin-top: 0;
    width: 100%;
    height: auto;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right .featured-image img {
    width: 100%;
  }
  .section-vantagens {
    padding-bottom: 60px;
  }
  .section-vantagens .vantagens-content {
    flex-direction: column;
  }
  .section-vantagens .vantagens-content .item {
    margin-bottom: 35px;
    width: 100%;
  }
  .section-contrate .section-contrate-container .container {
    height: auto;
  }
  .section-contrate .section-contrate-container .container .right {
    position: relative;
    width: 100%;
    top: -60px;
  }
  .section-contrate .section-contrate-container .container .right .box {
    padding: 50px 30px;
  }
  .section-contrate .section-contrate-container .container .right .box .price {
    width: 100%;
  }
  .section-contrate .section-contrate-container .container .right .box .price .moeda {
    font-size: 24px;
  }
  .section-contrate .section-contrate-container .container .right .box .price .valor {
    font-size: 60px;
  }
  .section-contrate .section-contrate-container .container .right .box .price .centavos {
    font-size: 28px;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content {
    flex-direction: column-reverse;
  }
  .section-contabilidade .section-contabilidade-container .contabilidade-content .right {
    transform: initial;
  }
  .section-contrate {
    padding: 60px 0;
  }
  .section-contrate .section-contrate-container .container .contrate-content {
    flex-direction: column;
  }
  .section-duvidas .section-duvidas-container .duvidas-content {
    flex-direction: column;
  }
  .section-duvidas .section-duvidas-container .duvidas-content .left {
    width: 100%;
    margin: 0;
  }
  .section-contrate .section-contrate-container .container .left img {
    display: none;
  }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation {
    margin-bottom: 35px;
  }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li {
    margin-bottom: 10px;
    border: 1px solid color(valhalla);
  }
  .section-duvidas .section-duvidas-container .duvidas-content .duvidas-navigation li.active {
    border: 0;
  }
  .section-duvidas .section-duvidas-container .duvidas-content .right {
    width: 100%;
  }
}
@media (min-width:320px) and (max-width:375px){
  .container-medium {
    padding: 0 15px;
  }
  .section-hero {
    padding-bottom: 40px;

    .bnt-wrapper {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 25px;

      .btn {
        width: 100%;
        margin: 0;
        margin-bottom: 25px;

        &:not(last-child){
          margin-right: 0!important;
        }
      }

    }
  }

  .section-hero .scrolldown {
    display: none;
  }
  .section-contrate .section-contrate-container .container .right .box .price .moeda {
    font-size: 24px;
  }
  .section-contrate .section-contrate-container .container .right .box .price .valor {
    font-size: 34px;
  }
  .section-contrate .section-contrate-container .container .right .box .price .centavos {
    font-size: 21px;
    position: relative;
  }
  .section-contrate .section-contrate-container .container .right .box .btn-conheca-planos {
    min-width: 195px;
  }
  .section-duvidas {

    .section-duvidas-container {

      .duvidas-content {

        .duvidas-navigation {

          li {
            padding: 0 15px;
          }
        }
      }
      .duvidas-navigation-content {
        padding: 30px 15px;

        ul {

          li {
            margin-bottom: 35px!important;
            font-size: 16px!important;
          }
        }
        .atendimento {

          .item {

            h6{
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.section-hero {
  .headline {
    color: white;
    font-weight: bold;
    max-width: 50%;

    @media (max-width: 568px) {
      text-align: center;
      max-width: none;
    }

    .line {
      display: block;

      &:not(:last-child) {
        margin-bottom: 40px;
        letter-spacing: 1px;

        @media (max-width: 568px) {
          margin-bottom: 30px;
        }
      }

      &-1 {
        font-size: 64px;

        @media (max-width: 568px) {
          font-size: 42px;
        }
      }

      &-2 {
        font-size: 96px;

        @media (max-width: 568px) {
          font-size: 56px;
        }
      }
    }
  }

  .button {
    text-transform: uppercase;
    display: inline-block;
    margin-top: 50px;

    .btn {
      min-width: none;
      padding: 0 50px;
    }

    @media (max-width: 568px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
