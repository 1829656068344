#main-footer {
	background-color: #060A32;
	position: relative;
	width: 100%;
	margin: 0;
	padding: 60px 0 45px;

	.flex-wrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		margin: 0;
		padding: 0;

		&:nth-child(2) {
			margin-top: 60px;
			padding-left: 250px;
		}

		.column {
			position: relative;
			margin: 0;
			padding: 0;

			.logo {
				position: relative;
				margin: 0;
				padding: 0;
				pointer-events: initial;

				a {
					display: inline-block;
				}
			}

			.navigation {
				position: relative;
				width: 100%;
				margin: 0;
				padding: 0;

				ul {
					position: relative;
					width: 100%;
					margin: 0 0 50px;
					padding: 0;

					li {
						position: relative;
						width: 100%;
						margin: 0;
						padding: 0;

						&:not(:last-child) {
							margin-bottom: 13px;
						}

						a {
							color: #8089FF;
							font-size: 12px;
							font-weight: normal;
							text-transform: uppercase;
							margin: 0;
							padding: 0;
							transition: color time(default) ease(inout);

							&:hover {
								color: white;
							}
						}
					}
				}
			}

			.contact {
				position: relative;
				width: 100%;
				margin: 0 0 60px;
				padding: 0;

				&-title {
					color: white;
					font-size: 14px;
					font-weight: bold;
					text-transform: uppercase;
					margin: 0;
					padding: 0;
				}

				&-phone {
					position: relative;
					display: flex;
					align-items: center;
					margin: 30px 0 0;
					padding: 0;

					&--icon {
						position: relative;
						margin: 0 20px 0 0;
						padding: 0;

						svg path {
							fill: #7780F1;
						}
					}

					&--content {
						position: relative;
						margin: 0;
						padding: 0;

						span {
							color: white;
							position: relative;
							display: inline-block;

							&:nth-child(1) {
								top: -8px;
								font-size: 18px;
								margin-right: 10px;
							}

							&:nth-child(2) {
								font-size: 28px;
							}
						}
					}
				}
			}

			.social {
				position: relative;
				margin: 0;
				padding: 0;

				ul {
					position: relative;
					display: flex;
					align-items: center;
					margin: 0;
					padding: 0;

					li {
						position: relative;
						margin: 0;
						padding: 0;

						&:not(:last-child) {
							margin-right: 40px;
						}

						a {
							display: inline-block;
							margin: 0;
							padding: 0;

							svg path {
								fill: white;
							}
						}
					}
				}
			}

			.newsletter {
				position: relative;
				width: 370px;
				margin: 0;
				padding: 0;

				&-title {
					color: white;
					font-size: 14px;
					font-weight: bold;
					text-transform: uppercase;
					margin: 0;
					padding: 0;
				}

				&-subtitle {
					color: #8089FF;
					font-size: 12px;
					font-weight: normal;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					margin: 15px 0 0;
					padding: 0;
				}

				&-form {
					position: relative;
					width: 100%;
					margin: 35px 0 0;
					padding: 0;

					.form {
						position: relative;
						display: flex;
						align-items: center;
						width: 100%;
						margin: 0;
						padding: 0;

						.field-group {
							position: relative;
							width: 100%;
							margin: 0;
							padding: 0;

							.placeholder {
								color: white;
								font-size: 12px;
								font-weight: normal;
								text-transform: uppercase;
								letter-spacing: 1.5px;
								position: absolute;
								top: 4px;
								left: 0;
								transition: opacity time(default) ease(inout);
							}

							.field {
								background-color: transparent;
								color: white;
								font-size: 14px;
								font-weight: normal;
								margin: 0;
								padding: 0 0 10px;
								display: block;
								width: 100%;
								border: none;
								border-bottom: 1px solid #8089FF;
								transition: border-color time(default) ease(inout);
							}

							&.focus,
							&.not-empty {
								.placeholder {
									opacity: 0;
								}
							}

							&.invalid .field {
								border-color: red;
							}
						}

						.form-submit {
							position: relative;
							margin: 0 0 0 15px;
							padding: 0;

							button {
								position: relative;
								display: flex;
								align-items: center;
								margin: 0;
								padding: 0;

								span {
									color: white;
									font-size: 12px;
									font-weight: bold;
									text-transform: uppercase;
									display: inline-block;
									margin: 0 10px 0 0;
									padding: 0;
								}

								svg {
									position: relative;
									margin: 0;
									padding: 0;

									path {
										fill: #8089FF;
									}
								}
							}
						}
					}
				}
			}
		}

		.copyright {
			color: #808080;
			font-size: 12px;
			font-weight: normal;
			margin: 0;
			padding: 0;
		}

		.assinatura {
			position: relative;
			margin: 0 0 0 35px;
			padding: 0;

			a {
				display: inline-block;
				margin: 0;
				padding: 0;
			}
		}
	}
}
